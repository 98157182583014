import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CookieNotificationComponent } from "@ss/ui/components/shared/cookie-notification/cookie-notification.component";
import { AppRoutingModule } from "./app-routing.module";

import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SigncoErrorHandler } from "@signco/core/error-handler";

import { ConfigurationService } from "@signco/services";

import { AdminGuard, AuthGuard, PendingChangesGuard } from "@signco/guards";

import { AuthInterceptor, DefaultInterceptor, JsonInterceptor } from "@signco/interceptors";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Angulartics2Module } from "angulartics2";
import { ToastrModule } from "ngx-toastr";

import { SharedModule } from "./ui/shared/shared.module";

import { ServiceWorkerModule } from "@angular/service-worker";
import { MSAL_INSTANCE, MsalBroadcastService, MsalRedirectComponent, MsalService } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { RootRedirectGuard } from "@signco/guards";
import { DomainDataService } from "@signco/services";
import { PageBodyComponent } from "@ss/ui/layout/page/page-body/page-body.component";
import { PageHeaderComponent } from "@ss/ui/layout/page/page-header/page-header.component";
import { PageComponent } from "@ss/ui/layout/page/page.component";
import { AppComponent } from "./app.component";

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/configuration.md
 */
export function msalConfigFactory(configurationService: ConfigurationService): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: configurationService.configuration.azureAuthenticationClientId,
            authority: configurationService.configuration.azureAuthenticationAuthority,
            redirectUri: window.location.origin,
            knownAuthorities: [configurationService.configuration.azureAuthenticationAuthority],
            postLogoutRedirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
            /**
             * Below you can configure MSAL.js logs. For more information, visit:
             * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
             */
            loggerOptions: {
                loggerCallback(logLevel: LogLevel, message: string) {
                    console.log(message);
                },
                logLevel: LogLevel.Warning,
                piiLoggingEnabled: false,
            },
        },
    });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,

        Angulartics2Module.forRoot(),
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        PageComponent,
        PageHeaderComponent,
        PageBodyComponent,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: !location.hostname.includes("localhost"),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000",
        }),
        CookieNotificationComponent,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: !location.hostname.includes("localhost"),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000",
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigurationService, domainDataService: DomainDataService) => async () => {
                // This is executed before the application starts. This means that the rest of the code can rely on
                // the configuration and the domain data being loaded.
                await AppComponent.attemptStartup(configService, domainDataService);
            },
            deps: [ConfigurationService, DomainDataService],
            multi: true,
        },
        AdminGuard,
        AuthGuard,
        RootRedirectGuard,
        PendingChangesGuard,
        [
            {
                provide: ErrorHandler,
                useClass: SigncoErrorHandler,
            },
        ],
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DefaultInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsonInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: msalConfigFactory,
            deps: [ConfigurationService],
        },
        MsalService,
        MsalBroadcastService,
    ],
    exports: [],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
