import { Component } from "@angular/core";
import { IClientCreatedResult } from "@signco/data-access/models/application";
import { AccessibilityService } from "@signco/services";
import { DialogComponentBase } from "@ss/ui/shared/components/dialog/dialog.component";

@Component({
    selector: "app-application-create-result-dialog",
    templateUrl: "./application-create-result.dialog.html",
})
export class ApplicationCreateResultDialogComponent extends DialogComponentBase {
    clientCreatedResult: IClientCreatedResult;
    copiedSecret: boolean;

    constructor(private readonly accessibilityService: AccessibilityService) {
        super();

        this.dismissOnClickOutside = false;
    }

    open(clientCreatedResult: IClientCreatedResult) {
        this.clientCreatedResult = clientCreatedResult;

        this.openDialog();
    }

    protected onClose() {
        this.copiedSecret = false;
        this.clientCreatedResult = null;
    }

    copyIdToClipboard() {
        this.accessibilityService.copyToClipboard(
            this.clientCreatedResult.id,
            "applications.copiedClientIdToClipboard",
        );
    }

    copySecretToClipboard() {
        this.copiedSecret = true;
        this.accessibilityService.copyToClipboard(
            this.clientCreatedResult.secret,
            "applications.copiedClientSecretToClipboard",
        );
    }
}
