import { Injectable } from "@angular/core";
import { IAssignment } from "@signco/data-access/models/assignment";
import { ICoordinate } from "@signco/data-access/models/location";
import { IParkingBan } from "@signco/data-access/models/parking-ban";
import { ServiceRequestOptions } from "@signco/data-access/models/search";
import { ITask } from "@signco/data-access/models/task";
import { Subject } from "rxjs";

type INew = {
    coordinate: ICoordinate;
    assignment: IAssignment;
};

@Injectable({
    providedIn: "root",
})
export class AssignmentsService {
    //region Assignment
    private _assignmentCoordinate: ICoordinate = undefined;
    get assignmentCoordinate() {
        return this._assignmentCoordinate;
    }

    setAssignmentCoordinate(coordinate: ICoordinate) {
        this._assignmentCoordinate = coordinate;
    }
    //endregion

    //region Parent Assignment
    private _parentAssignment: IAssignment = undefined;
    get parentAssignment() {
        return this._parentAssignment;
    }
    setParentAssignment(assignment: IAssignment) {
        this._parentAssignment = assignment;
    }
    //endregion

    //region New Task
    private _newTask: INew = undefined;
    get newTask() {
        return this._newTask;
    }
    setNewTask(coordinate: ICoordinate, assignment: IAssignment) {
        this._newTask = { coordinate, assignment };
    }

    tasksChanged$ = new Subject<ITask>();

    getTaskServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("task", "location");
        options.includes.add("task", "assignment");
        options.includes.add("task", "currentStatus");
        options.includes.add("task", "copiedTask");
        options.includes.add("taskStatusHistory", "workerTasks");
        options.includes.add("workerTask", "worker");
        options.includes.add("assignment", "parentAssignment");
        options.includes.add("assignment", "project");
        return options;
    }
    //endregion

    //region Parking Ban
    private _newParkingBan: INew = undefined;
    get newParkingBan() {
        return this._newParkingBan;
    }

    setNewParkingBan(coordinate: ICoordinate, assignment: IAssignment) {
        this._newParkingBan = { coordinate, assignment };
    }

    parkingBanChanged$ = new Subject<IParkingBan>();

    getParkingBanServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("parkingBan", "location");
        options.includes.add("parkingBan", "assignment");
        options.includes.add("assignment", "parentAssignment");
        options.includes.add("assignment", "project");
        return options;
    }
    //endregion
}
