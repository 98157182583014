import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import {
    AssignmentSubtaskSearchParameters,
    AssignmentSubtaskType,
    IAssignment,
    IAssignmentSubtask,
} from "@signco/data-access/models/assignment";
import { ILocationWithAssignmentsSummary } from "@signco/data-access/models/web";
import { AssignmentApi } from "@signco/data-access/resource/assignment.api";
import { NavigationService } from "@signco/services";
import { MarkerContext } from "@ss/ui/components/map-advanced/classes";

@Component({
    selector: "app-assignment-subtasks",
    templateUrl: "./assignment-subtasks.component.html",
})
export class AssignmentSubtasksComponent implements OnChanges {
    @Input() context: MarkerContext;
    @Output() selected = new EventEmitter<IAssignmentSubtask | IAssignmentSubtask[]>();
    @Output() deselected = new EventEmitter<IAssignmentSubtask | IAssignmentSubtask[]>();
    @Output() dataSet = new EventEmitter<void>();

    public selectedSubtasks: IAssignmentSubtask[];
    public data: IAssignmentSubtask[];
    public loading: boolean;
    public readonly subtaskTaskType: AssignmentSubtaskType = AssignmentSubtaskType.Task;

    private selectedAssignmentIds: number[];

    constructor(
        private assignmentApi: AssignmentApi,
        private navigationService: NavigationService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const contextChange = changes["context"];
        if (contextChange) {
            const summary = this.context.locationSummary as ILocationWithAssignmentsSummary;
            this.data = [];
            this.fetchData(summary);
        }
    }

    public navigateToDetail(data: IAssignmentSubtask) {
        switch (data.type) {
            case AssignmentSubtaskType.Task:
                this.navigationService.toTask(data.assignmentId, data.id);
                break;
            case AssignmentSubtaskType.ParkingBan:
                this.navigationService.toParkingBan(data.assignmentId, data.id);
                break;
            default:
                this.navigationService.toAssignmentDetails(data.assignmentId);
        }
    }

    public emitSelect(event: { data: IAssignmentSubtask }) {
        this.selected.next(event.data);
    }

    public emitDeselect(event: { data: IAssignmentSubtask }) {
        this.deselected.next(event.data);
    }

    public setAssignmentSelection(assignments: IAssignment[]) {
        this.selectedAssignmentIds = assignments ? assignments.map((a) => a.id) : [];
        this.refreshDefaultSelection();
    }

    private fetchData(locationSummary: ILocationWithAssignmentsSummary) {
        if (!locationSummary || !locationSummary.id || !locationSummary.assignmentIds) {
            return;
        }

        this.loading = true;
        const params = {} as AssignmentSubtaskSearchParameters;

        params.assignmentIds = locationSummary.assignmentIds;

        if (!params.assignmentIds) {
            params.locationId = locationSummary.id;
        }

        this.assignmentApi.getSubtasksSummary$(params).subscribe((data: IAssignmentSubtask[]) => {
            this.data = data;
            this.loading = false;
            this.dataSet.next();
            this.refreshDefaultSelection();
        });
    }

    private refreshDefaultSelection() {
        this.selectedSubtasks = this.data
            ? this.data.filter((d) => this.selectedAssignmentIds.contains(d.assignmentId))
            : [];
    }
}
