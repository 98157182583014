import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Constants } from "@signco/core/constants";
import { LocalStorageService } from "@signco/services";

@Component({
    selector: "app-cookie-notification",
    templateUrl: "./cookie-notification.component.html",
    imports: [TranslateModule],
    standalone: true,
})
export class CookieNotificationComponent {
    visible = false;

    constructor(private readonly localStorage: LocalStorageService) {
        this.visible = !this.localStorage.getItem(Constants.cookieNotificationStorageKey);
    }

    acceptCookies() {
        this.localStorage.setItem(Constants.cookieNotificationStorageKey, "true");
        this.visible = false;
    }
}
