import { Injectable } from "@angular/core";
import { ExistsResult } from "@signco/data-access/models/search";
import { VmsImageUpdater } from "@signco/data-access/models/vms-image";
import { Observable } from "rxjs";
import { IVmsImageVariant, VmsImageVariantCreator, VmsImageVariantUploadCreator } from "../models/vms-image-variant";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class VmsImageVariantApi extends ApiService<IVmsImageVariant, VmsImageVariantCreator, VmsImageUpdater> {
    getRoute(): string {
        return "VmsImageVariants";
    }

    upload$(creator: VmsImageVariantUploadCreator, file: File): Observable<IVmsImageVariant> {
        const url = `${super.getUrl()}/Upload`;

        const formData = new FormData();
        formData.append("config", JSON.stringify(creator));
        formData.append("data", file);
        return this.http.post<IVmsImageVariant>(url, formData);
    }

    exists$(code: string, typeId: string, ownerId?: string): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;

        if (!ownerId) return this.http.get<ExistsResult>(url, { params: { code: code, typeId: typeId } });

        return this.http.get<ExistsResult>(url, { params: { ownerId: ownerId, code: code, typeId: typeId } });
    }

    preview$(svgData: string, width: number, height: number): Promise<Blob> {
        const svgObject = {
            data: svgData,
            width: width,
            height: height,
        };
        const url = `${super.getUrl()}/Preview`;
        return this.http.post(url, svgObject, { responseType: "blob" }).toPromise();
    }
}
