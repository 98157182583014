import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { SubscriptionManager } from "@signco/core/utils";
import { MetaApi } from "@signco/data-access/resource/meta.api";
import { ErrorService, WebsiteService } from "@signco/services";
import { SharedModule } from "@ss/ui/shared/shared.module";
import { interval } from "rxjs";

@Component({
    selector: "app-service-status",
    templateUrl: "./service-status.component.html",
    imports: [SharedModule],
    standalone: true,
})
export class ServiceStatusComponent implements OnInit, OnDestroy {
    private static readonly POLL_TIME_SECONDS = 10;

    private readonly subscriptionManager = new SubscriptionManager();
    pollTimer: number;
    online: boolean;

    private readonly metaApi = inject(MetaApi);
    private readonly errorService = inject(ErrorService);
    protected readonly websiteService = inject(WebsiteService);

    constructor() {}

    ngOnInit() {
        // Instantly try to reconnect
        this.resetTimer(1);

        const onServiceOk = () => {
            // Use the browser to navigate back to ourselves
            // Seeing how we load domainData in a pre-application-step
            window.location.href = this.errorService.getPreviousAvailablePageAsRawWebsiteUrl();
        };

        const onError = () => {
            this.resetTimer();
        };

        const pollTimerSubscription = interval(1000).subscribe(() => {
            if (this.pollTimer === 0) return;

            this.pollTimer--;

            if (this.pollTimer === 0) {
                this.metaApi.status$().subscribe(onServiceOk, onError);
            }
        });

        this.subscriptionManager.add("pollTimerSubscription", pollTimerSubscription);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    private resetTimer(timer = ServiceStatusComponent.POLL_TIME_SECONDS) {
        console.clear();
        this.errorService.clearErrors();
        this.pollTimer = timer;
        this.online = window.navigator.onLine;
    }
}
