import { Injectable } from "@angular/core";
import { DateInfo, IDateInfoCollection } from "@signco/data-access/models/date-info";
import { IGroup } from "@signco/data-access/models/group";
import { IProject } from "@signco/data-access/models/project";
import { DeviceApi } from "@signco/data-access/resource/device.api";
import { GroupApi } from "@signco/data-access/resource/group.api";
import { MeasuringPointApi } from "@signco/data-access/resource/measuring-point.api";
import { ProjectApi } from "@signco/data-access/resource/project.api";
import { debounceTime, Subject, Subscription } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DataDaysService {
    private devices = new Array<number>();
    private measuringPoints = new Array<number>();
    private groups = new Array<IGroup>();
    private projects = new Array<IProject>();
    private dataDays = new Array<DateInfo>();
    private deviceStatusDays = new Array<DateInfo>();
    private dateInfoSubject = new Subject<number[]>();
    private dateInfoObservable = this.dateInfoSubject.asObservable();
    private getDatesCallSubscription: Subscription;

    constructor(
        private readonly deviceApi: DeviceApi,
        private readonly measuringPointApi: MeasuringPointApi,
        private readonly groupApi: GroupApi,
        private readonly projectApi: ProjectApi,
    ) {
        this.dateInfoObservable.pipe(debounceTime(2000)).subscribe((measuringPointIds) => {
            this.getDataDatesForMeasuringPoints(measuringPointIds);
        });
    }

    clear() {
        this.dataDays = [];
        this.deviceStatusDays = [];
    }

    addDevices(newDevices: number[]) {
        this.devices = this.devices.concat(newDevices || []).distinct();

        for (const device of newDevices) {
            this.getDataDatesForDevice(device);
        }
    }

    removeDevices(removedDevices: number[]) {
        this.devices = this.devices.filter((x) => !removedDevices.contains(x));
        this.removeDataForDevices(removedDevices);
    }

    private removeDataForDevices(removedDevices: number[]) {
        this.deviceStatusDays = this.deviceStatusDays.filter(
            (x) => !x.deviceId || !removedDevices.contains(x.deviceId),
        );
    }

    setMeasuringPoints(newMeasuringPoints: number[]) {
        this.measuringPoints = newMeasuringPoints;

        if (newMeasuringPoints.length < 10) {
            this.getDataDatesForMeasuringPoints(newMeasuringPoints);
        } else {
            // Cancel current call, queue new
            if (this.getDatesCallSubscription) {
                this.getDatesCallSubscription.unsubscribe();
                this.getDatesCallSubscription = null;
            }

            this.dateInfoSubject.next(newMeasuringPoints);
        }
    }

    addGroups(newGroups: IGroup[]) {
        this.groups = this.groups.concat(newGroups || []).distinct();

        for (const group of newGroups) {
            this.getDataDatesForGroup(group);
        }
    }

    removeGroups(removedGroups: IGroup[]) {
        this.groups = this.groups.filter((x) => !removedGroups.contains(x));
        this.removeDataForGroups(removedGroups);
    }

    private removeDataForGroups(removedGroups: IGroup[]) {
        this.dataDays = this.dataDays.filter((x) => !x.group || !removedGroups.contains(x.group));
    }

    private getDataDatesForDevice(device: number) {
        const onSuccess = (dataDates: IDateInfoCollection) => {
            if (!this.devices.contains(device)) return;

            dataDates.dates.forEach((x) => {
                x.deviceId = device;
            });

            this.removeDataForDevices([device]);

            this.deviceStatusDays = this.deviceStatusDays.concat(dataDates.dates);
        };

        this.deviceApi.getStatusDates$(device).subscribe(onSuccess);
    }

    private getDataDatesForMeasuringPoints(measuringPointIds: number[]) {
        this.dataDays = this.dataDays.filter((x) => !x.measuringPointDate);
        if (!measuringPointIds?.length) return;

        const onSuccess = (dataDates: IDateInfoCollection) => {
            if (measuringPointIds !== this.measuringPoints) return;

            dataDates.dates.forEach((x) => {
                x.measuringPointDate = true;
            });

            this.getDatesCallSubscription = null;

            this.dataDays = this.dataDays.filter((x) => !x.measuringPointDate).concat(dataDates.dates);
        };

        this.getDatesCallSubscription = this.measuringPointApi.getDataDates$(measuringPointIds).subscribe(onSuccess);
    }

    private getDataDatesForGroup(group: IGroup) {
        const onSuccess = (dataDates: IDateInfoCollection) => {
            if (!this.groups.contains(group)) return;

            dataDates.dates.forEach((x) => {
                x.group = group;
            });

            this.removeDataForGroups([group]);

            this.dataDays = this.dataDays.concat(dataDates.dates);
        };

        this.groupApi.getDataDates$(group.id).subscribe(onSuccess);
    }

    addProjects(projects: IProject[]) {
        this.projects = this.projects.concat(projects || []).distinctFunc((x) => x.id);

        for (const project of projects) {
            this.getDataDatesForProject(project);
        }
    }

    private getDataDatesForProject(project: IProject) {
        const onSuccess = (dataDates: IDateInfoCollection) => {
            if (!this.projects.find((x) => x.id === project.id)) return;

            dataDates.dates.forEach((x) => {
                x.projectId = project.id;
            });

            this.removeDataForProjects([project]);

            this.dataDays = this.dataDays.concat(dataDates.dates);
        };

        this.projectApi.getDataDates$(project.id).subscribe(onSuccess);
    }

    removeProjects(removedProjects: IProject[]) {
        this.projects = this.projects.filter(
            (x) => !removedProjects.find((removedProject) => removedProject.id === x.id),
        );
        this.removeDataForProjects(removedProjects);
    }

    private removeDataForProjects(removedProjects: IProject[]) {
        this.dataDays = this.dataDays.filter(
            (x) => !x.projectId || !removedProjects.find((project) => project.id === x.projectId),
        );
    }

    // Rework to return a CSS class
    getCssStyle(date: { day: number; month: number; year: number }): string {
        if (!date) return "";

        const list = this.dataDays.length ? this.dataDays : this.deviceStatusDays;

        const dataDay = list.find(
            (x) =>
                x.date.getDate() === date.day && x.date.getMonth() === date.month && x.date.getFullYear() === date.year,
        );
        if (!dataDay) return "";

        if (dataDay.isError) return "m-date-error";
        if (dataDay.isException) return "m-date-exception";
        if (dataDay.isPredicted) return "m-date-predicted";

        return "m-date-highlight";
    }
}
