import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JsonUtils } from "@signco/core/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// https://github.com/angular/angular/blob/master/packages/common/http/src/xhr.ts#L18

/**
 * Provide custom json parsing capabilities for api requests.
 * @export
 * @class JsonInterceptor
 */
@Injectable()
export class JsonInterceptor implements HttpInterceptor {
    /**
     * Custom http request interceptor
     * @public
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof JsonInterceptor
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (typeof req.body === "object") {
            req = req.clone({
                body: this.prepareBodyToSend(req.body),
            });
        }

        if (req.responseType !== "json") {
            return next.handle(req);
        }

        // convert to responseType of text to skip angular parsing
        req = req.clone({
            responseType: "text",
        });

        return next.handle(req).pipe(
            map((event) => {
                // Pass through everything except for the final response.
                if (!(event instanceof HttpResponse)) {
                    return event;
                }
                return JsonUtils.processJsonResponse(event);
            }),
        );
    }

    prepareBodyToSend(body: any): any {
        if (!body) return body;
        if (body instanceof FormData) return body;

        const stringified = JsonUtils.stringify(body);

        // Don't parse using decoder
        // This will, for instance, leave duration in their "HH:mm:ss" notation
        return JSON.parse(stringified);
    }
}
