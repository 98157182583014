import { Component, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MeasuringPointUtils } from "@signco/core/utils";
import { IDevice } from "@signco/data-access/models/device";
import { SigncoFormGroup } from "@signco/data-access/models/form";
import { IMeasuringPoint, IMeasuringPointNavigator } from "@signco/data-access/models/measuring-point";
import { IUpload } from "@signco/data-access/models/upload";
import { VehicleMeasurementDataDeleter } from "@signco/data-access/models/vehicle-overview";
import { MeasuringPointApi } from "@signco/data-access/resource/measuring-point.api";
import { CalendarSettings, DataDaysService, FormValidationService, PrimeComponentService } from "@signco/services";
import { DialogComponentBase } from "../dialog/dialog.component";

@Component({
    selector: "app-measuring-point-data-delete-dialog",
    templateUrl: "./measuring-point-data-delete.dialog.html",
})
export class MeasuringPointDataDeleteDialogComponent extends DialogComponentBase implements OnDestroy {
    measuringPoint: IMeasuringPointNavigator;
    device?: IDevice;
    callback: () => void;
    submitting: boolean;
    visible: boolean;

    initialFrom: Date;
    initialTo: Date;
    rangeDeleteForm: SigncoFormGroup;
    timeDeleteForm: SigncoFormGroup;
    dataDeleteForm: SigncoFormGroup;

    calendarSettings: CalendarSettings;

    constructor(
        readonly formValidationService: FormValidationService,
        readonly dataDaysService: DataDaysService,
        readonly primeComponentService: PrimeComponentService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly measuringPointApi: MeasuringPointApi,
    ) {
        super();

        const calendarSettingsSubscription = this.primeComponentService
            .calendarSettings()
            .subscribe((calendarSettings) => {
                this.calendarSettings = calendarSettings;
            });
        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    open(measuringPoint: IMeasuringPoint, from?: Date, to?: Date, callback?: () => void) {
        this.measuringPoint = MeasuringPointUtils.toNavigator(measuringPoint);
        this.callback = callback;
        this.initialFrom = from;
        this.initialTo = to;
        this.openDialog();
    }

    openForUpload(upload: IUpload, callback?: () => void, device?: IDevice) {
        this.measuringPoint = upload.measuringPoint;
        this.device = device;
        this.callback = callback;
        this.initialFrom = upload.firstData;
        this.initialTo = upload.lastData;
        this.openDialog();
    }

    private updateDataDays() {
        this.dataDaysService.clear();

        if (this.measuringPoint) {
            this.dataDaysService.setMeasuringPoints([this.measuringPoint.id]);
        }
    }

    protected onOpen() {
        this.updateDataDays();

        this.initialFrom = this.initialFrom || new Date().addDays(-7);
        this.initialTo = this.initialTo || new Date();

        this.rangeDeleteForm = this.formBuilder.group({
            from: [this.initialFrom, Validators.required],
            to: [this.initialTo, Validators.required],
        }) as SigncoFormGroup;

        this.initialFrom = new Date(this.initialFrom).toMidnight();
        this.initialTo = new Date(this.initialTo).toMidnight();
        const start = new Date().toMidnight();

        const end = new Date(start);
        end.setHours(8);

        this.timeDeleteForm = this.formBuilder.group({
            from: [this.initialFrom, Validators.required],
            to: [this.initialTo, Validators.required],
            start: [start, Validators.required],
            end: [end, Validators.required],
        }) as SigncoFormGroup;
        this.timeDeleteForm.disable();

        this.dataDeleteForm = this.formBuilder.group({
            rangeDeleteForm: this.rangeDeleteForm,
            timeDeleteForm: this.timeDeleteForm,
        }) as SigncoFormGroup;

        this.submitting = false;
        this.visible = true;
    }

    protected onClose() {
        this.dataDeleteForm = null;
        this.timeDeleteForm = null;
        this.rangeDeleteForm = null;
    }

    setDateForm(formGroup: SigncoFormGroup) {
        this.rangeDeleteForm.disable();
        this.timeDeleteForm.disable();
        formGroup.enable();
    }

    async submit() {
        const isValid = await this.formValidationService.checkValidity(this.dataDeleteForm);
        if (!isValid) return;

        this.submitting = true;

        const onSuccess = () => {
            if (this.callback) {
                this.callback();
            }

            this.submitting = false;
            this.close();
        };

        const onError = () => {
            this.submitting = false;
        };

        const deleters = new Array<VehicleMeasurementDataDeleter>();

        if (this.rangeDeleteForm.enabled) {
            const deleter = new VehicleMeasurementDataDeleter(
                this.rangeDeleteForm.get("from").value as Date,
                this.rangeDeleteForm.get("to").value as Date,
            );

            deleters.push(deleter);
        }

        if (this.timeDeleteForm.enabled) {
            const setTime = (date: Date, time: Date) => {
                date.setHours(time.getHours());
                date.setMinutes(time.getMinutes());
                date.setSeconds(0);
                date.setMilliseconds(0);
            };

            const from = this.timeDeleteForm.get("from").value as Date;
            const start = this.timeDeleteForm.get("start").value as Date;
            const end = this.timeDeleteForm.get("end").value as Date;
            setTime(from, start);

            const to = this.timeDeleteForm.get("to").value as Date;

            for (let date = from; date <= to; date = date.addDays(1)) {
                const dateTo = new Date(date);
                setTime(dateTo, end);

                const deleter = new VehicleMeasurementDataDeleter(date, dateTo);

                deleters.push(deleter);
            }
        }

        const deviceId = this.device ? this.device.id : null;
        this.measuringPointApi
            .removeVehicles$(this.measuringPoint.id, deleters, deviceId)
            .subscribe(onSuccess, onError);
    }
}
