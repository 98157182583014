import { Injectable } from "@angular/core";
import {
    DataSetType,
    IHistoricalData,
    ILiveData,
    IPinnedDataConfigurationPerAnalysisType,
} from "@signco/data-access/models/pinned-data";
import { VehicleCategory } from "@signco/data-access/models/vehicle";
import {
    MeasuringPointPinnedDataWebApi,
    MeasuringPointPinnedHistoryDataSearchParameters,
    MeasuringPointPinnedLiveDataSearchParameters,
} from "@signco/data-access/resource/web";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class PinnedDataService {
    constructor(private readonly measuringPointPinnedDataApi: MeasuringPointPinnedDataWebApi) {}

    isHistoricalDataAvailable(pinnedDataConfiguration: IPinnedDataConfigurationPerAnalysisType): boolean {
        return pinnedDataConfiguration?.supportedOptions && pinnedDataConfiguration?.supportedOptions.length > 0;
    }

    getVehicleCategoryBasedOnDataSetType(dataSetType: DataSetType): VehicleCategory {
        switch (dataSetType) {
            case DataSetType.IntensityBikes:
                return VehicleCategory.Bike;
            case DataSetType.IntensityMopeds:
                return VehicleCategory.Moped;
            case DataSetType.IntensityHeavy:
                return VehicleCategory.Heavy;
            case DataSetType.IntensityMedium:
                return VehicleCategory.Medium;
            case DataSetType.IntensityLight:
                return VehicleCategory.Light;
            case DataSetType.IntensityPedestrians:
                return VehicleCategory.Pedestrian;
            default:
                return VehicleCategory.Unknown;
        }
    }

    loadLiveData$(
        measuringPointId: number,
        measuringPointPinnedLiveDataSearchParameters: MeasuringPointPinnedLiveDataSearchParameters,
        useCache: boolean,
    ): Observable<ILiveData> {
        return this.measuringPointPinnedDataApi.getLiveData$(
            measuringPointId,
            measuringPointPinnedLiveDataSearchParameters,
            useCache,
        );
    }

    loadHistoricalData$(
        measuringPointId: number,
        measuringPointPinnedHistoricalDataSearchParameters: MeasuringPointPinnedHistoryDataSearchParameters,
    ): Observable<IHistoricalData> {
        return this.measuringPointPinnedDataApi.getHistoricalData$(
            measuringPointId,
            measuringPointPinnedHistoricalDataSearchParameters,
        );
    }
}
