import { WeatherForecastSummary } from "@signco/data-access/models/web/weather-forecast-summary";
import { ILocation } from "../location";
import { IDeviceStatusSummary, IMeasuringPointStatusSummary } from "./";
import { IAggregatedLog } from "./aggregated-log";

export interface ILocationStatusSummary {
    location: ILocation;
    measuringPoints: IMeasuringPointStatusSummary[];
    devices: IDeviceStatusSummary[];
    lastUpdate: Date;
    forecasts: WeatherForecastSummary[];
    aggregatedLogs: IAggregatedLog[];
}
