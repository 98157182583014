@if(config?.title){
<h3 class="mb-8">{{config.title}}</h3>
}

<div *ngIf="config"
     class="timeline"
     [style.--items]="activities.length"
     [style.--days]="days.length"
     [style.--zoom]="zoom">
    <div class="timeline__head">
        <div class="activity__description"></div>

        <div *ngFor="let activity of activities"
             class="activity__description">
            {{activity.key}}
        </div>
    </div>

    <div #timelineBody
         class="timeline__body"
         appScrollDrag
         [disableDrag]="false">
        <div class="units">
            <ng-container *ngFor="let day of [].constructor(days.length); let i = index">
                <div class="unit"
                     *ngFor="let hour of [].constructor(24); let j = index">
                    <h3 *ngIf="j === 0"
                        class="date">
                        {{days[i] | date:'dd/MM/yyyy'}}
                    </h3>

                    @if(shouldShowHour(j)){
                    <span class="time">
                        {{j}}h
                    </span>
                    }
                </div>
            </ng-container>
        </div>

        <div *ngFor="let activity of activities"
             class="activity__grid">

            <div *ngFor="let data of activity.data"
                 class="activity__grid__item"
                 [style.--start]="data.startHour +1"
                 [style.--end]="data.endHour +1"
                 [style.--color]="data.color"
                 [pTooltip]="data.tooltip">
                <span>{{data.tooltip}}</span>
            </div>
        </div>
    </div>
</div>