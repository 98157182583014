import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SubscriptionManager } from "@signco/core/utils";
import { SigncoFormControl } from "@signco/data-access/models/form";
import { FormValidationService } from "@signco/services";
import { Calendar } from "primeng/calendar";

@Component({
    selector: "app-form-validation",
    templateUrl: "./form-validation.component.html",
    styleUrls: ["./form-validation.component.css"],
})
export class FormValidationComponent implements OnInit, OnDestroy {
    // This is an AbstractControl but AOT complains that it can't find "c.showErrors" in html
    // Stupid.
    @Input() control: SigncoFormControl;
    @Input() input?: HTMLElement;

    private previousValue: string;

    private readonly subscriptionManager = new SubscriptionManager();

    constructor(
        translateService: TranslateService,
        private readonly formValidationService: FormValidationService,
    ) {
        const langChangeSubscription = translateService.onLangChange.subscribe(() => {
            this.updateErrors(); // on language change
        });
        this.subscriptionManager.add("langChangeSubscription", langChangeSubscription);
    }

    ngOnInit() {
        this.subscriptionManager.add(
            "valueChanges",
            this.control.valueChanges.subscribe(() => {
                if (this.control.value === this.previousValue) return;
                this.previousValue = this.control.value;

                this.updateErrors();
            }),
        );

        this.subscriptionManager.add(
            "stateChanges",
            this.control.statusChanges.subscribe(() => {
                this.updateErrors();
            }),
        );

        // https://github.com/angular/angular/issues/10887
        const markAsPristine = this.control.markAsPristine;
        this.control.markAsPristine = (opts: any) => {
            if (markAsPristine) markAsPristine.call(this.control, opts);
            this.control.markAsUntouched();
            this.updateErrors();
        };

        if (this.input) {
            const currentOnBlur = this.input.onblur;

            const onBlur = (focusEvent: FocusEvent) => {
                this.updateErrors();

                if (currentOnBlur && currentOnBlur !== onBlur) {
                    currentOnBlur.call(this.input, focusEvent);
                }
            };

            this.input.onblur = onBlur;

            // PrimeNG components can have inputs embedded
            // they have an "onBlur" EventEmitter<any> that you can subscribe to
            if ((this.input as any).onBlur && (this.input as any).onBlur.subscribe) {
                this.subscriptionManager.add(
                    "onBlur",
                    (this.input as any).onBlur.subscribe(() => {
                        if (this.input instanceof Calendar) {
                            if (!this.input.value) {
                                const inputValue = this.input.inputfieldViewChild.nativeElement.value;

                                try {
                                    const date = this.input.parseDate(inputValue, this.input.dateFormat);

                                    if (date) {
                                        this.input.updateModel(date);
                                        this.input.updateUI();
                                    }
                                } catch {}
                            }
                        }

                        this.updateErrors();
                    }),
                );
            }
        }
    }

    private updateErrors() {
        this.formValidationService.setErrorMessages(this.control);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }
}
