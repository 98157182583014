import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { IVmsDisplayedImage } from "@signco/data-access/models/device";
import { IVmsFrame } from "@signco/data-access/models/mode-configuration";
import { VmsImageVariantApi } from "@signco/data-access/resource/vms-image-variant.api";
import { lastValueFrom } from "rxjs";

class AnimatedImageViewModel {
    displayTimeMs: number;
    uri?: string; // Some images can be accessed throught their url
    imagePreview?: string; // Other images need to be downloaded instead
}

@Component({
    selector: "app-vms-animation",
    templateUrl: "./vms-animation.component.html",
})
export class VmsAnimationComponent implements OnInit, OnChanges {
    // We can either bind to IImageDisplayImage or IVmsFrame
    @Input() images: IVmsDisplayedImage[];
    @Input() frames: IVmsFrame[];
    @Input() imageStyle: string;

    viewModels: AnimatedImageViewModel[];
    currentIndex: number;

    timeout: NodeJS.Timeout;

    constructor(private readonly vmsImageVariantApi: VmsImageVariantApi) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        const imagesChange = changes["images"];
        if (imagesChange) {
            let hasChanged = false;
            // check if the images array has changed
            const previousValue = imagesChange.previousValue;
            const currentValue = imagesChange.currentValue;
            // only do a check if both arrays have the same length
            // if the length is different, then the array has changed
            if (previousValue && currentValue && previousValue.length === currentValue.length) {
                for (let i = 0; i < previousValue.length; i++) {
                    if (previousValue[i].uri !== currentValue[i].uri) {
                        hasChanged = true;
                        break;
                    }
                }
            } else {
                hasChanged = true;
            }
            if (hasChanged) {
                this.initialize();
            }
        }

        const framesChange = changes["frames"];
        if (framesChange) {
            this.initialize();
        }
    }

    async initialize() {
        if (this.images?.length) {
            this.viewModels = [];
            for (const image of this.images) {
                const viewModel: AnimatedImageViewModel = {
                    uri: image.uri,
                    displayTimeMs: image.displayTime,
                };
                this.viewModels.push(viewModel);
            }
        }

        if (this.frames?.length) {
            // Unfortunately it takes a lot of server calls to retrieve all images
            this.viewModels = [];
            for (const frame of this.frames) {
                const vmsImageVariant = await lastValueFrom(this.vmsImageVariantApi.get$(frame.vmsImageVariantId));

                const viewModel: AnimatedImageViewModel = {
                    uri: vmsImageVariant.url,
                    displayTimeMs: frame.displayTimeMs,
                };
                this.viewModels.push(viewModel);
            }
        }

        if (!this.viewModels || !this.viewModels.length) return;

        if (this.viewModels.length > 1) {
            this.next(0);
        } else {
            // No timer needed
            this.currentIndex = 0;
        }
    }

    next(index: number = 0) {
        if (index >= this.viewModels.length) return; // Race condition when amount of frames is now less
        this.currentIndex = index;
        let displayTime = this.viewModels[index].displayTimeMs;
        if (displayTime < 100) displayTime = 100; // Prevent problems when the time is configured as 0
        index = (index + 1) % this.viewModels.length;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.next(index);
        }, displayTime);
    }

    moveLeft() {
        clearTimeout(this.timeout);
        let index = (this.currentIndex - 1 + this.viewModels.length) % this.viewModels.length;
        this.next(index);
    }
    moveRight() {
        clearTimeout(this.timeout);
        let index = (this.currentIndex + 1) % this.viewModels.length;
        this.next(index);
    }
}
