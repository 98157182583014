import { Pipe, PipeTransform } from "@angular/core";
import { Vehicle } from "@signco/data-access/models/vehicle";

@Pipe({
    name: "vehicle",
})
export class VehiclePipe implements PipeTransform {
    transform(vehicle: Vehicle | string): string {
        let parsedVehicle: Vehicle;
        if (vehicle instanceof Vehicle) {
            parsedVehicle = vehicle;
        } else {
            parsedVehicle = Vehicle.deserializeFromSignco(vehicle);
        }
        if (!parsedVehicle) return "?";
        return `[${parsedVehicle.timestamp.toLocaleString()}] ${parsedVehicle.category}`;
    }
}
