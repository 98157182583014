import { Injectable } from "@angular/core";
import { FileUtils } from "@signco/core/utils";
import { DownloadedFile } from "@signco/services";
import { Observable } from "rxjs";
import { AttachmentCreator, AttachmentUpdater, IAttachment } from "../models/attachment";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class AttachmentApi extends ApiService<IAttachment, AttachmentCreator, AttachmentUpdater> {
    getRoute(): string {
        return "attachment";
    }

    create$(model: AttachmentCreator, routeParams: { [key: string]: string } = null): Observable<IAttachment> {
        throw new Error("Use upload$");
    }

    upload$(creator: AttachmentCreator, file: File): Observable<IAttachment> {
        const url = `${super.getUrl()}`;
        const formData = new FormData();
        formData.append("config", JSON.stringify(creator));
        formData.append("data", file);

        return this.http.post<IAttachment>(url, formData);
    }

    async replace$(
        attachment: IAttachment,
        existingPhoto: DownloadedFile,
        imagePreview: String,
        creator: AttachmentCreator,
        image: File,
    ) {
        if (attachment && existingPhoto) {
            const oldImageBase64 = (await FileUtils.toBase64(existingPhoto.file)).split("base64");
            const imagePreview64 = imagePreview ? imagePreview.split("base64") : [];
            // split to skip format part and compare the content
            if (oldImageBase64.length > 0 && imagePreview64.length > 0 && oldImageBase64[1] === imagePreview64[1]) {
                // it's the same photo
                return attachment;
            }

            // remove the old attachment
            this.delete$(attachment.id).toPromise();
        }
        return await this.upload$(creator, image).toPromise();
    }

    updateWithFile$(updater: AttachmentUpdater, file: File): Observable<IAttachment> {
        const url = `${super.getUrl()}/${updater.id}/Form`;
        const formData = new FormData();
        formData.append("config", JSON.stringify(updater));
        formData.append("data", file);

        return this.http.put<IAttachment>(url, formData);
    }
}
