import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertLevel } from "@signco/data-access/models/alert";
import { ArrowBoardDirection, ArrowBoardPosition, TrafficLightState } from "@signco/data-access/models/device";
import { IMapIcon, IMapIconState, MapIconType } from "@signco/data-access/models/map-icon";
import { ILocationSummary } from "@signco/data-access/models/web";
import { MapIconApi } from "@signco/data-access/resource/map-icon.api";
import { firstValueFrom, ReplaySubject } from "rxjs";
import { DomainDataService } from "./domain-data.service";
import { GlobalEventsService } from "./global-events.service";
import { GoogleMapsLoaderService } from "./google-maps-loader.service";

// for each MapIconState, this has the google maps icons
export class MapIconStateViewModel {
    configuration: IMapIconState; // The data from the BE

    // This can only be invoked after Google Maps is loaded!
    public get defaultIcon(): google.maps.Icon {
        const result = {
            url: this.configuration.default.url,
            size: new google.maps.Size(this.configuration.default.width, this.configuration.default.height),
            anchor: new google.maps.Point(0, 0),
            // anchor: new google.maps.Point(this.configuration.default.anchorX, this.configuration.default.anchorY),
        };
        return result;
    }

    // This can only be invoked after Google Maps is loaded!
    public get selectedIcon(): google.maps.Icon {
        const result = {
            url: this.configuration.selected.url,
            size: new google.maps.Size(this.configuration.selected.width, this.configuration.selected.height),
            anchor: new google.maps.Point(0, 0),
            // anchor: new google.maps.Point(this.configuration.selected.anchorX, this.configuration.selected.anchorY),
        };
        return result;
    }
}

@Injectable({
    providedIn: "root",
})
export class MapIconService {
    private readonly mapIconsApi = inject(MapIconApi);
    private readonly googleMapsLoader = inject(GoogleMapsLoaderService);
    private readonly domainDataService = inject(DomainDataService);
    private readonly translateService = inject(TranslateService);
    private readonly globalEventsService = inject(GlobalEventsService);

    private initializationHasStarted = false;
    private mapIcons: IMapIcon[] = [];
    private iconStates: Map<number, MapIconStateViewModel>;
    private organizationIcon: MapIconStateViewModel;
    private customIcons: IMapIcon[];
    private trafficLightIcons: IMapIcon[];
    private arrowBoardIcons: IMapIcon[];

    private initializationSubject = new ReplaySubject<void>(); // This means component still get the event, even if they subscribe after the event has been emitted
    public onInitialized = this.initializationSubject.asObservable();

    constructor() {
        this.translateService.onLangChange.subscribe(() => {
            this.updateTranslationOfCustomIcons();
        });

        this.googleMapsLoader.load().then(() => {
            this.initialize();
        });
    }

    private async initialize() {
        if (this.iconStates) return; // We are already initialized

        this.initializationHasStarted = true;
        this.mapIcons = await this.mapIconsApi.getAll();

        this.iconStates = new Map<number, MapIconStateViewModel>();
        this.customIcons = [];
        this.trafficLightIcons = [];
        this.arrowBoardIcons = [];

        for (const mapIcon of this.mapIcons) {
            if (mapIcon.mapIconTypeId === MapIconType.CustomIcon) {
                mapIcon.code = this.domainDataService.translate(mapIcon.codeStringResourceId);
                this.customIcons.push(mapIcon); // The location-UI to select an icon needs these
            }

            if (mapIcon.mapIconTypeId === MapIconType.TrafficLight) {
                this.trafficLightIcons.push(mapIcon);
            }

            if (mapIcon.mapIconTypeId === MapIconType.ArrowBoard) {
                this.arrowBoardIcons.push(mapIcon);
            }

            for (const mapIconState of mapIcon.states) {
                const model = new MapIconStateViewModel();
                model.configuration = mapIconState;

                this.iconStates.set(mapIconState.id, model);
                if (mapIcon.mapIconTypeId === MapIconType.Organization) this.organizationIcon = model;
            }
        }

        this.initializationSubject.next();
        this.initializationSubject.complete(); // Notify that no new values will come, this also unsubscribes all subscribers
    }

    public async waitUntilInitialized() {
        if (this.iconStates) return; // We are already initialized

        if (this.initializationHasStarted) {
            // We know initialiation has started, but it might be in progress, so we wait
            // If it is already done, this will return immediately.
            await firstValueFrom(this.onInitialized);
        }

        // Initilatization was not started, we will start it and wait for it to finish
        await this.initialize();
    }

    public getIconState(iconStateId?: number): MapIconStateViewModel {
        if (!this.iconStates) return null;
        if (!iconStateId) return null;

        return this.iconStates.get(iconStateId);
    }

    public getOrganizationIcon(): MapIconStateViewModel {
        return this.organizationIcon;
    }

    public getTrafficLightIcon(state: TrafficLightState): IMapIcon {
        const result = this.trafficLightIcons.filter((x) => x.trafficLightStateId === state).takeFirstOrDefault();
        return result;
    }

    public getArrowBoardIcon(directionId: ArrowBoardDirection, positionId: ArrowBoardPosition): IMapIcon {
        const result = this.arrowBoardIcons
            .filter((x) => x.arrowBoardDirectionId === directionId && x.arrowBoardPositionId === positionId)
            .takeFirstOrDefault();
        return result;
    }

    public getCustomIcons(): IMapIcon[] {
        return this.customIcons;
    }

    private async updateTranslationOfCustomIcons() {
        if (!this.customIcons || this.customIcons.length === 0) return;

        for (const customIcon of this.customIcons) {
            customIcon.code = this.domainDataService.translate(customIcon.codeStringResourceId);
        }
    }

    public getLocationIcon(locationSummary: ILocationSummary): MapIconStateViewModel | null {
        if (locationSummary.iconStateId) {
            // Easy case: the location already knows what icon to use
            return this.getIconState(locationSummary.iconStateId);
        }

        if (locationSummary.iconStateIds) {
            // More complex case: the location has multiple icons, for different user roles
            const roles = this.globalEventsService.currentRoles.getValue();

            // Filter the icons to only those that are allowed for the current user
            const filteredIcons = locationSummary.iconStateIds.filter((icon) => roles.includes(icon.roleId));
            if (filteredIcons.length === 0) {
                // SCS-504 There used to be a problem when the user only had the DomainAdmin role, we did not implicitly add all roles yet.
                console.error("filteredIcons is empty");
                return null;
            }

            // order by alert level. Get highest alert level (null is lowest)
            // Priority map for AlertLevel
            const alertLevelPriority: { [key in AlertLevel]: number } = {
                info: 1,
                warning: 2,
                error: 3,
            };
            function compareAlertLevels(level1?: AlertLevel, level2?: AlertLevel): number {
                const defaultLowPriority = -1;
                const priority1 = alertLevelPriority[level1] ?? defaultLowPriority;
                const priority2 = alertLevelPriority[level2] ?? defaultLowPriority;

                return priority1 - priority2;
            }

            const selectedIcon = filteredIcons.reduce((prev, current) =>
                compareAlertLevels(prev?.al, current?.al) > 0 ? prev : current,
            );

            return this.getIconState(selectedIcon.icId);
        }

        return null;
    }
}
