import { IProject } from "@signco/data-access/models/project";
import { IProjectsTreeOrganization, IProjectsTreeProject } from "@signco/data-access/models/projects-tree-organization";
import { TreeNode } from "primeng/api";

export class ProjectTreeUtils {
    public static isActiveProject(project: IProject): boolean {
        return project.isActive;
    }

    static getProjectStyle(node: TreeNode) {
        const project = node.data as IProjectsTreeProject;

        const isActive = project.isActive;
        if (isActive) {
            return "";
        } else {
            return { color: "#999" };
        }
    }

    static filterTreeNodes(nodes: TreeNode[], filterValue: string): TreeNode[] {
        let filteredNodes = [];
        if (filterValue == "") {
            // Deep copy
            filteredNodes = [...nodes];
        } else {
            filteredNodes = [];
            const filterText = filterValue.toLowerCase();
            for (const node of nodes) {
                const copyNode = { ...node };
                if (this._isFilterMatched(copyNode, filterText) || this._findFilteredNodes(copyNode, filterText)) {
                    filteredNodes.push(copyNode);
                }
            }
        }
        return filteredNodes;
    }

    private static _isFilterMatched(node: TreeNode, filterText: string) {
        let matched = false;
        if (node.label.toLowerCase().indexOf(filterText) > -1) {
            matched = true;
        }
        if (!matched) {
            matched = this._findFilteredNodes(node, filterText) || matched;
        }
        return matched;
    }

    private static _findFilteredNodes(node: TreeNode, filterText: string) {
        if (node) {
            let matched = false;
            if (node.children) {
                const childNodes = [...node.children];
                node.children = [];
                for (const childNode of childNodes) {
                    const copyChildNode = { ...childNode };
                    if (this._isFilterMatched(copyChildNode, filterText)) {
                        matched = true;
                        node.children.push(copyChildNode);
                    }
                }
            }
            if (matched) {
                node.expanded = true;
                return true;
            }
        }
        return false;
    }

    static mapProjectsTreeToTreeNodes(organizations: IProjectsTreeOrganization[]): TreeNode[] {
        const nodes: TreeNode[] = [];
        organizations.forEach((organization) => {
            const organizationNode: TreeNode = {
                label: organization.name,
                data: organization,
                type: "organization",
                expanded: true,
                // expandedIcon: "pi pi-home",
                // collapsedIcon: "pi pi-home",
                children: [],
                key: "org-" + organization.id,
            };
            // Add SubOrganizations - recursive
            this._addSubOrganizationsAndProjects(organization, organizationNode);
            nodes.push(organizationNode);
        });

        return nodes;
    }

    private static _addSubOrganizationsAndProjects(organization: IProjectsTreeOrganization, node: TreeNode) {
        // Add SubOrganizations - recursive
        organization.subOrganizations.forEach((subOrganization) => {
            const subOrganizationNode: TreeNode = {
                label: subOrganization.name,
                data: subOrganization,
                type: "organization",
                expanded: false,
                children: [],
                // expandedIcon: "pi pi-home",
                // collapsedIcon: "pi pi-home",
                key: "org-" + subOrganization.id,
            };
            this._addSubOrganizationsAndProjects(subOrganization, subOrganizationNode);
            node.children.push(subOrganizationNode);
        });
        // Add projects
        this._addProjects(organization, node);
    }

    private static _addProjects(organization: IProjectsTreeOrganization, node: TreeNode) {
        organization.projects.forEach((project) => {
            const projectNode: TreeNode = {
                label: project.name,
                data: project,
                type: "project",
                expanded: false,
                // expandedIcon: "pi pi-folder-open",
                // collapsedIcon: "pi pi-folder",
                children: [],
                key: "proj-" + project.id,
            };

            // Add subprojects - recursive
            this._addSubProjects(project, projectNode);
            node.children.push(projectNode);
        });
    }
    private static _addSubProjects(project: IProjectsTreeProject, node: TreeNode) {
        project.subProjects.forEach((subProject) => {
            const subProjectNode: TreeNode = {
                label: subProject.name,
                data: subProject,
                type: "project",
                expanded: false,
                children: [],
                // expandedIcon: "pi pi-folder-open",
                // collapsedIcon: "pi pi-folder",
                key: "proj-" + subProject.id,
            };
            node.children.push(subProjectNode);
            // Recursive
            this._addSubProjects(subProject, subProjectNode);
        });
    }
}
