import "@signco/core/extensions";
import * as ExifReader from "exifreader";

export class FileUtils {
    static getExtension(filename: string): string {
        const indexOf = (filename || "").lastIndexOf(".");
        if (indexOf < 0) return filename;

        return filename.substr(indexOf + 1).toLowerCase();
    }

    static getNameWithoutExtension(filename: string): string {
        let name = filename;

        const periodIndex = name.lastIndexOf(".");
        if (periodIndex > -1) {
            name = name.substring(0, periodIndex);
        }

        return name;
    }

    static isPhotoUrl(url: string): boolean {
        const extension = this.getExtension(url);
        return this.imageExtensions.contains(extension);
    }

    static isVideoUrl(url: string): boolean {
        const extension = this.getExtension(url);
        return this.videoExtensions.contains(extension);
    }

    static isPdfUrl(url: string): boolean {
        const extension = this.getExtension(url);
        return extension === "pdf";
    }

    static isPreviewableInGalleria(url: string): boolean {
        return this.isPhotoUrl(url) || this.isVideoUrl(url) || this.isPdfUrl(url);
    }

    static isPhoto(file: File): boolean {
        if (!file) return false;

        return file.type.contains("image");
    }

    static async toBase64(file: File): Promise<string> {
        if (!file) return null;

        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result as string);
            };

            reader.onerror = (error: ProgressEvent) => {
                reject(error);
            };

            // Supports DownloadedFiles better
            const fileOrBlob = (file as any).blob ? (file as any).blob : file;
            reader.readAsDataURL(fileOrBlob);
        });
    }

    static async getFileDate(file: File): Promise<Date> {
        if (!file) return null;
        if (!this.isPhoto(file)) return new Date(file.lastModified);

        return new Promise<Date>((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                try {
                    const tags = ExifReader.load(reader.result as ArrayBuffer);

                    const dateTimeOriginalTag = tags["DateTimeOriginal"];
                    if (dateTimeOriginalTag) {
                        const unprocessedTagValue = dateTimeOriginalTag.value;
                        if (unprocessedTagValue && unprocessedTagValue.length) {
                            // format: YY:HH:MM hh:mm:ss
                            const dateString = unprocessedTagValue[0];

                            const dateStringSplit = dateString.split(" ");

                            // Get date part and replace ':' with '-'
                            const dateStr = dateStringSplit[0].replace(/:/g, "-");

                            // Concat the strings (date and time part)
                            const properDateStr = dateStr + " " + dateStringSplit[1];

                            // Pass to Date
                            const exifDate = new Date(properDateStr);

                            resolve(new Date(exifDate));
                        }
                        return;
                    }

                    resolve(new Date(file.lastModified));
                } catch {
                    // Happens on non-jpeg photos
                    resolve(new Date(file.lastModified));
                }
            };

            reader.onerror = (e: ProgressEvent) => {
                reject(e);
            };

            reader.readAsArrayBuffer(file);
        });
    }

    static rotateImage(image: HTMLImageElement, fileType: string, degrees: number): HTMLImageElement {
        const canvas = document.createElement("canvas");

        canvas.width = image.height;
        canvas.height = image.width;

        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.translate(image.height / 2, image.width / 2);

        ctx.rotate((degrees * Math.PI) / 180);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);

        const newImage = new Image();
        newImage.src = canvas.toDataURL(fileType);

        return newImage;
    }

    /**
     * Convert a base64 string in a Blob according to the data and contentType.
     * @param b64Data {String} Pure base64 string without contentType
     * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
     * @param sliceSize {Int} SliceSize to process the byteCharacters
     * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     * @return Blob
     */
    static imageToFile(image: HTMLImageElement, filename: string, lastModified: number = null): File {
        const block = image.src.split(";");
        const contentType = block[0].split(":")[1];
        const b64Data = block[1].split(",")[1];
        const byteCharacters = atob(b64Data);
        const byteArrays: Uint8Array[] = [];

        const sliceSize = 512;

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const options = {} as FilePropertyBag;
        options.type = contentType;

        if (lastModified) {
            options.lastModified = lastModified;
        }

        return new File(byteArrays, filename, options);
    }

    static getPreviewFromFilename(filename: string): string {
        let file = "empty-preview.png";
        const extension = FileUtils.getExtension(filename).toLowerCase();

        if (extension.contains("xls")) {
            file = "excel-preview.png";
        }

        if (extension.contains("doc")) {
            file = "word-preview.png";
        }

        if (extension.contains("ppt")) {
            file = "ppt-preview.png";
        }

        if (extension === "pdf") {
            file = "pdf-preview.png";
        }

        if (this.isVideoUrl(filename)) {
            file = "video-preview.png";
        }

        if (extension === "kmz" || extension === "kml") {
            file = "kmz-preview.png";
        }

        return `/assets/img/${file}`;
    }

    // tslint:disable-next-line:member-ordering
    static readonly imageExtensions = [
        "ase",
        "art",
        "bmp",
        "blp",
        "cd5",
        "cit",
        "cpt",
        "cr2",
        "cut",
        "dds",
        "dib",
        "djvu",
        "egt",
        "exif",
        "gif",
        "gpl",
        "grf",
        "icns",
        "ico",
        "iff",
        "jng",
        "jpeg",
        "jpg",
        "jfif",
        "jp2",
        "jps",
        "lbm",
        "max",
        "miff",
        "mng",
        "msp",
        "nitf",
        "ota",
        "pbm",
        "pc1",
        "pc2",
        "pc3",
        "pcf",
        "pcx",
        "pdn",
        "pgm",
        "PI1",
        "PI2",
        "PI3",
        "pict",
        "pct",
        "pnm",
        "pns",
        "ppm",
        "psb",
        "psd",
        "pdd",
        "psp",
        "px",
        "pxm",
        "pxr",
        "qfx",
        "raw",
        "rle",
        "sct",
        "sgi",
        "rgb",
        "int",
        "bw",
        "tga",
        "tiff",
        "tif",
        "vtf",
        "xbm",
        "xcf",
        "xpm",
        "3dv",
        "amf",
        "ai",
        "awg",
        "cgm",
        "cdr",
        "cmx",
        "dxf",
        "e2d",
        "egt",
        "eps",
        "fs",
        "gbr",
        "odg",
        "svg",
        "stl",
        "vrml",
        "x3d",
        "sxd",
        "v2d",
        "vnd",
        "wmf",
        "emf",
        "art",
        "xar",
        "png",
        "webp",
        "jxr",
        "hdp",
        "wdp",
        "cur",
        "ecw",
        "iff",
        "lbm",
        "liff",
        "nrrd",
        "pam",
        "pcx",
        "pgf",
        "sgi",
        "rgb",
        "rgba",
        "bw",
        "int",
        "inta",
        "sid",
        "ras",
        "sun",
        "tga",
    ];

    // tslint:disable-next-line:member-ordering
    static readonly videoExtensions = [
        "3g2",
        "3gp",
        "aaf",
        "asf",
        "avchd",
        "avi",
        "drc",
        "flv",
        "m2v",
        "m4p",
        "m4v",
        "mkv",
        "mng",
        "mov",
        "mp2",
        "mp4",
        "mpe",
        "mpeg",
        "mpg",
        "mpv",
        "mxf",
        "nsv",
        "ogg",
        "ogv",
        "qt",
        "rm",
        "rmvb",
        "roq",
        "svi",
        "vob",
        "webm",
        "wmv",
        "yuv",
    ];
}
