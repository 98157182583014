import { Injectable } from "@angular/core";
import { Constants } from "@signco/core/constants";
import { SubscriptionManager } from "@signco/core/utils";
import { Rights } from "@signco/data-access/models/rights";
import { IOrganization, Roles } from "@signco/data-access/models/user";
import { RolesApi } from "@signco/data-access/resource/roles.api";
import { Observable, Subject } from "rxjs";
import { LocalStorageService } from "./storage.service";

@Injectable({
    providedIn: "root",
})
export class ImpersonationService {
    get organization(): IOrganization {
        return this._organization;
    }

    get organizationId(): number {
        return this._organization ? this._organization.id : this.loadedOrganizationId;
    }
    get role(): Roles {
        return this._role;
    }

    set role(role: Roles) {
        if (role === this._role) return;

        this._role = role;
        //  get rights
        if (role) {
            this.rolesApi.getRights$(role).subscribe((rights) => {
                this._rights = new Rights(rights);
                this.roleImpersonationSubject.next(this.role);
            });
        } else {
            this._rights = null;
            this.roleImpersonationSubject.next(this.role);
        }

        this.saveToStorage();
    }

    get rights(): Rights {
        return this._rights;
    }

    //#endregion Role

    constructor(
        private readonly localStorage: LocalStorageService,
        private readonly rolesApi: RolesApi,
    ) {
        // Currently disabled => Causing circular dependency when reloading
        // ->
        // in short you cannot use a service within your interceptor itself using HttpClient
        // because the HttpClient has a dependency on the interceptor.
        // A workaround is to provide a brand new instance of HttpClient free of any interceptors.
        // In human terms
        // We have interceptors that use impersonation service
        // Impersonation service uses RolesApi
        // Which gets a HttpClient
        // Which has interceptors
        // Which has impersonation service
        // Which has RolesApi
        // Which gets HttpClient
        // which has interceptors
        // ....
        // this.loadFromStorage();
    }

    private readonly subscriptionManager = new SubscriptionManager();
    private organizationImpersonationSubject = new Subject<IOrganization>();
    private roleImpersonationSubject = new Subject<Roles>();
    private requestOrganizationSubject = new Subject<void>();

    //#region Organization

    loadedOrganizationId: number;
    private _organization: IOrganization;

    //#endregion Organization

    //#region Role

    private _role: Roles;
    private _rights: Rights;

    setOrganization(organization: IOrganization) {
        if (organization === this._organization) return;
        if (organization && this._organization && organization.id === this._organization.id) return;

        const wasLoadingOrganization = organization && this.loadedOrganizationId === organization.id;

        // Reset this, no longer needed
        this.loadedOrganizationId = null;

        const previousOrganization = this._organization;
        this._organization = organization;
        this.saveToStorage();

        // Don't reload if we're simply updating reference to organization
        if (
            wasLoadingOrganization ||
            (previousOrganization && organization && previousOrganization.id === organization.id)
        )
            return;

        this.reloadData();
    }

    isImpersonating(): boolean {
        return !!this.loadedOrganizationId || !!this.organization;
    }

    clear(triggerReload = true) {
        this._organization = null;
        this._role = null;

        this.saveToStorage();

        if (triggerReload) {
            this.reloadData();
        }
    }

    private reloadData() {
        this.organizationImpersonationSubject.next(this.organization);
    }

    //#region Events

    //#region Request Organization

    private onRequestOrganization(): Observable<void> {
        return this.requestOrganizationSubject.asObservable();
    }

    subscribeToOrganizationRequests(key: string, onRequestOrganization: () => void) {
        const subscription = this.onRequestOrganization().subscribe(onRequestOrganization);
        this.subscriptionManager.add(`${key}-Request`, subscription);
    }

    //#endregion

    //#region Organization impersonation

    private onOrganizationImpersonation(): Observable<IOrganization> {
        return this.organizationImpersonationSubject.asObservable();
    }

    subscribeToOrganizationImpersonation(key: string, onUpdate: (organization: IOrganization) => void) {
        const subscription = this.onOrganizationImpersonation().subscribe(onUpdate);
        this.subscriptionManager.add(`${key}-OrganizationImpersonation`, subscription);
    }

    //#endregion

    //#region Role impersonation

    private onRoleImpersonation(): Observable<Roles> {
        return this.roleImpersonationSubject.asObservable();
    }

    subscribeToRoleImpersonation(key: string, onUpdate: (role: Roles) => void) {
        const subscription = this.onRoleImpersonation().subscribe(onUpdate);
        this.subscriptionManager.add(`${key}-RoleImpersonation`, subscription);
    }

    //#endregion

    unsubscribe(key: string) {
        this.subscriptionManager.remove(`${key}-OrganizationImpersonation`);
        this.subscriptionManager.remove(`${key}-RoleImpersonation`);
        this.subscriptionManager.remove(`${key}-Request`);
    }

    //#endregion

    //#region Storage

    private loadFromStorage() {
        const cachedOrganizationId = this.localStorage.getItem(Constants.organizationImpersonationStorageKey);
        if (cachedOrganizationId) {
            this.loadedOrganizationId = Number.parseInt(cachedOrganizationId, 10);
            this.requestOrganizationSubject.next();
        }

        const cachedRole = this.localStorage.getItem(Constants.roleImpersonationStorageKey) as Roles;
        if (cachedRole) {
            this.role = cachedRole;
        }
    }

    private saveToStorage() {
        if (this._organization) {
            this.localStorage.setItem(Constants.organizationImpersonationStorageKey, this.organization.id.toString());
        } else {
            this.localStorage.removeItem(Constants.organizationImpersonationStorageKey);
        }

        if (this._role) {
            this.localStorage.setItem(Constants.roleImpersonationStorageKey, this._role);
        } else {
            this.localStorage.removeItem(Constants.roleImpersonationStorageKey);
        }
    }

    //#endregion Storage
}
