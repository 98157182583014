import { Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core";
import { DateRangePipe } from "../../pipes/date-range.pipe";

@Component({
    selector: "app-timeline-activity",
    templateUrl: "./timeline-activity.component.html",
    styleUrls: ["./timeline-activity.component.scss"],
})

export class TimelineActivityComponent {
    @ViewChild("timelineBody", { static: false }) timelineBody: ElementRef<HTMLDivElement>;

    private _config: TimelineConfig;
    @Input() set config(config: TimelineConfig) {
        this.setConfig(config);
    }

    get config() {
        return this._config;
    }

    protected days: Date[];
    protected activities: { key: string, data: TimelineData[] }[];
    protected zoom = 2;

    private setConfig(config: TimelineConfig) {
        this._config = config;

        if (this.config) {
            const earliestDay = new Date(Math.min(...this.config.data.map(x => x.startDate.getTime()))).toMidnight();
            const lastDay = new Date(Math.max(...this.config.data.map(x => x.endDate ? x.endDate.getTime() : new Date().getTime()))).toMidnight();

            const days = new Array<Date>();
            let curDay = earliestDay;
            const earliestTime = curDay.getTime();

            while (curDay <= lastDay) {
                days.push(curDay);
                curDay = curDay.addDays(1);
            }

            for (const data of this.config.data) {
                data.startHour ??= Math.round(Math.abs(data.startDate.getTime() - earliestTime) / 36e5);

                const hasEndDate = !!data.endDate;

                if (!hasEndDate) {
                    data.endDate = new Date();//lastDay.addDays(1);
                }

                data.endHour ??= Math.round(Math.abs(data.endDate.getTime() - earliestTime) / 36e5);

                const dateRangePipeOutput = DateRangePipe.transform({ from: data.startDate, until: hasEndDate ? data.endDate : null });
                data.tooltip ??= `${data.description} (${dateRangePipeOutput})`;
                data.totalHours ??= data.endHour - data.startHour;
            }

            this.days = days;

            const activities: { key: string, data: TimelineData[] }[] = [];
            for (const activity of this.config.data.groupByFunc(x => x.groupField ?? x.description)) {
                activities.push({ key: activity.key, data: activity.members });
            }
            this.activities = activities;

        } else {
            this.days.length = 0;
            this.activities.length = 0;
        }

        setTimeout(() => {
            this.timelineBody.nativeElement.scrollLeft = this.timelineBody.nativeElement.scrollWidth;
        });
    }

    // appScrollDrag already handles the left-and-right, with ctrl we'd like to zoom
    @HostListener("wheel", ["$event"])
    protected handleScroll(event: WheelEvent) {
        if (!event.ctrlKey) return;

        const isScrollingUp = event.deltaY < 0;

        const calculatedZoom = this.zoom + (0.2 * (isScrollingUp ? 1 : -1));
        this.zoom = Number(Math.min(4, Math.max(0.5, calculatedZoom)).toFixed(1));
        event.stop();
    }

    protected shouldShowHour(index: number): boolean {
        if (this.zoom >= 1.5) return true;

        const moduloFactor = this.zoom >= 1 ? 2 :
            this.zoom >= 0.8 ? 3 :
                this.zoom >= 0.6 ? 4 :
                    this.zoom >= 0.4 ? 6 :
                        this.zoom >= 0.2 ? 12 :
                            12;

        return index % moduloFactor == 0
    }
}

export class TimelineConfig {
    title: string;
    data: TimelineData[];
}

export class TimelineData {
    groupField?: string;
    description: string;
    startDate: Date;
    endDate?: Date;
    color?: string;
    icon?: string;
    startHour?: number;
    endHour?: number;
    totalHours?: number;
    tooltip?: string;
}