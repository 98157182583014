import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NumberUtils } from "@signco/core/utils";
import { IDeviceDisplayRule } from "@signco/data-access/models/device-display-configuration";
import moment, { isDuration } from "moment";

// Usage example:
// <div [innerHTML] = "deviceDisplayEvent.rule | readableDeviceDisplayRule:translateService.currentLang | safeHtml"></div>
@Pipe({ name: "readableDeviceDisplayRule" })
export class ReadableDeviceDisplayRulePipe implements PipeTransform {
    includeDays: { [key: string]: string } = {
        includeMondays: "monday",
        includeTuesdays: "tuesday",
        includeWednesdays: "wednesday",
        includeThursdays: "thursday",
        includeFridays: "friday",
        includeSaturdays: "saturday",
        includeSundays: "sunday",
    };

    includePeriods: { [key: string]: string } = {
        includeHolidays: "holidays",
        includeVacations: "vacations",
        includeBridgeDays: "bridgeDays",
    };

    constructor(private readonly translateService: TranslateService) {}

    // We need language to force-refresh the pipe on lang change
    // This isn't worth it to go for an impure pipe, in my honest opinion
    // Robin
    transform(rule: IDeviceDisplayRule, language: string) {
        if (!rule) return "";

        let result = "<ul class='device-display-event-rule'>";

        const listify = (value: string): string => {
            return `<li>${value}</li>`;
        };

        const toString = (value: any, prefix?: string, postfix?: string): string => {
            if (value === undefined || value === null) return "";

            if (value instanceof Date) {
                value = moment(value).format("L HH:mm");
            }

            if (isDuration(value)) {
                value = moment.utc(value.as("milliseconds")).format("HH:mm:ss");
            }

            if (prefix === undefined || prefix === null) prefix = "";
            if (postfix === undefined || postfix === null) postfix = "";

            return `${prefix}${value}${postfix}`;
        };

        const addBetween = (valueString: string, min: any, max: any, prefix?: string, postfix?: string) => {
            if (!NumberUtils.isValid(min) && !NumberUtils.isValid(max)) return;

            valueString = "<span>" + this.translateService.instant(valueString) + "</span>";

            const minString = toString(min, prefix, postfix);
            const maxString = toString(max, prefix, postfix);
            let text = "";

            if (NumberUtils.isValid(min) && NumberUtils.isValid(max)) {
                if (min === max) {
                    text = `${valueString} = ${minString}`;
                } else {
                    text = `${minString} <= ${valueString} <= ${maxString}`;
                }
            } else if (NumberUtils.isValid(min)) {
                text = `${minString} <= ${valueString}`;
            } else {
                text = `${valueString} <= ${maxString}`;
            }

            result += listify(text);
        };

        if (rule.triggerOnScenarioActivate) {
            result += listify(this.translateService.instant("deviceDisplayEventRules.activationRules"));
        }

        addBetween("deviceDisplayEventRules.date", rule.minDate, rule.maxDate);
        addBetween("deviceDisplayEventRules.time", rule.minTime, rule.maxTime);

        const includedDays = new Array<string>();
        for (const includedDay in this.includeDays) {
            if (rule[includedDay]) {
                includedDays.push(
                    this.translateService.instant(`calendar.dayNamesShort.${this.includeDays[includedDay]}`) as string,
                );
            }
        }

        // Don't show if it's applicable to all days
        if (includedDays.length > 0 && includedDays.length < 7) {
            result += listify(includedDays.join(", "));
        }

        const includedPeriods = new Array<string>();
        for (const includedPeriod in this.includePeriods) {
            if (rule[includedPeriod]) {
                includedPeriods.push(
                    this.translateService.instant(`calendar.${this.includePeriods[includedPeriod]}`) as string,
                );
            }
        }

        // Don't show if it's applicable to all Periods
        if (includedPeriods.length > 0 && includedPeriods.length < 4) {
            result += listify(includedPeriods.join(", "));
        }

        addBetween("deviceDisplayEventRules.dayCount", rule.minDayCount, rule.maxDayCount);
        addBetween("deviceDisplayEventRules.monthCount", rule.minMonthCount, rule.maxMonthCount);
        addBetween("deviceDisplayEventRules.yearCount", rule.minYearCount, rule.maxYearCount);
        addBetween(
            "deviceDisplayEventRules.temperature",
            rule.minTemperature,
            rule.maxTemperature,
            null,
            this.translateService.instant("measurements.c"),
        );
        addBetween(
            "deviceDisplayEventRules.speed",
            rule.minSpeedInKmh,
            rule.maxSpeedInKmh,
            null,
            this.translateService.instant("measurements.kmh"),
        );

        if (NumberUtils.isValid(rule.durationInSeconds)) {
            const duration = this.translateService.instant("deviceDisplayEventRules.durationInSeconds");
            const seconds = this.translateService.instant("measurements.seconds");
            result += listify(`<span>${duration}</span>: ${rule.durationInSeconds} ${seconds}`);
        }

        if (NumberUtils.isValid(rule.durationInCentimeters)) {
            const duration = this.translateService.instant("deviceDisplayEventRules.durationInCentimeters");
            const cm = this.translateService.instant("measurements.cm");
            result += listify(`<span>${duration}</span>: ${rule.durationInCentimeters} ${cm}`);
        }

        result += "</ul>";

        return result;
    }
}
