/**
 * Improves marker performance by:
 * * Clustering if the user is zoomed out
 * * Hiding markers that are out of view
 *
 * This file encapsulates all implementation logic. Use `createMapClusterer` to retrieve an interface that represents the clusturer
 * // https://github.com/googlemaps/js-marker-clusterer/blob/gh-pages/src/markerclusterer.js
 */

import { environment } from "@env/environment";
import { NumberUtils } from "@signco/core/utils";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { IBasicMarker } from "./basic-marker";

export function createMapClusterer(
    map: google.maps.Map,
    markers?: google.maps.marker.AdvancedMarkerElement[],
    options?: any,
): IMarkerClusterer {
    if (!loaded) loadTypes();

    return new MapClusterer(map, markers, options);
}

export interface IMarkerClusterer {
    getMap(): google.maps.Map;
    setGridSize(gridSize: number): void;
    getGridSize(): number;
    getMinClusterSize(): number;
    getStyles(): ClusterStyle[];
    getMaxZoom(): number;
    getExtendedBounds(bounds: google.maps.LatLngBounds): google.maps.LatLngBounds;
    calculator(markers: IBasicMarker[], numStyles: number): { text: string; index: number };
    isZoomOnClick(): boolean;
    resetViewport(hideMarkers: boolean): void;
    requestRepaint(): void;
    addMarker(marker: IBasicMarker): void;
    removeMarker(marker: IBasicMarker, redraw?: boolean): void;
    getMarkers(): IBasicMarker[];
    clear(): void;
    getImagePathFunction(): (markers: IBasicMarker[]) => string;
    getImageExtension(): string;
    updateIcon(marker: IBasicMarker): void;
    updateIcons(): void;
    setUnloadOutOfRangeMarkers(unloadOutOfRangeMarkers: boolean): void;
    getUnloadOutOfRangeMarkers(): boolean;
    shouldCluster(zoomLevel?: number): boolean;
    dispose(): void;
    isPanning(): boolean;
}

interface ICluster {
    mapClusterer: IMarkerClusterer;
    map: google.maps.Map;
    gridSize: number;
    minClusterSize: number;
    center: google.maps.LatLng;
    markers: google.maps.marker.AdvancedMarkerElement[];
    bounds: google.maps.LatLngBounds;
    clusterIcon: IClusterIcon;
}

interface IClusterIcon {
    remove(): any;
    hide(): any;
    show(): any;
    setCenter(center: google.maps.LatLng): void;
    setSums(sums: any): void;
    isVisible(): boolean;
}

let loaded = false;

// we disable linting for these constructor functions, the convention is PascalCase.
/* eslint-disable @typescript-eslint/naming-convention */
let ClusterIcon: new (
    cluster: ICluster,
    styles: ClusterStyle[],
    padding: number,
    imagePathFunction: (markers: google.maps.marker.AdvancedMarkerElement[]) => any,
) => IClusterIcon;
let MapClusterer: new (
    map: google.maps.Map,
    markers?: google.maps.marker.AdvancedMarkerElement[],
    options?: any,
) => IMarkerClusterer;
/* eslint-enable @typescript-eslint/naming-convention */

class Cluster implements ICluster {
    mapClusterer: IMarkerClusterer;
    map: google.maps.Map;
    gridSize: number;
    minClusterSize: number;
    center: google.maps.LatLng;
    markers: IBasicMarker[];
    bounds: google.maps.LatLngBounds;
    clusterIcon: IClusterIcon;

    constructor(mapClusterer: IMarkerClusterer) {
        this.mapClusterer = mapClusterer;
        this.map = mapClusterer.getMap();
        this.gridSize = mapClusterer.getGridSize();
        this.minClusterSize = mapClusterer.getMinClusterSize();
        this.center = null;
        this.markers = [];
        this.bounds = null;
        this.clusterIcon = new ClusterIcon(
            this,
            mapClusterer.getStyles(),
            mapClusterer.getGridSize(),
            mapClusterer.getImagePathFunction(),
        );
    }

    isMarkerAlreadyAdded(markerToCheck: IBasicMarker): boolean {
        return this.markers.indexOf(markerToCheck) !== -1;
    }

    addMarker(marker: IBasicMarker, immediatelyUpdateUI = true): void {
        if (this.isMarkerAlreadyAdded(marker)) {
            return;
        }

        if (!this.center) {
            this.center = marker.getPosition();
            this.calculateBounds();
        }

        (marker as any).isAdded = true;
        (marker as any).cluster = this;
        this.markers.push(marker);

        if (immediatelyUpdateUI) {
            this.updateIconAndMarkerMap(marker, this.getRelevantMarkers().length);
            this.updateIcon();
        }
    }

    updateIconAndMarkerMap(marker: IBasicMarker, len: number): void {
        const shouldCluster = this.mapClusterer.shouldCluster();
        if (!shouldCluster) return;

        if (len < this.minClusterSize) {
            // Min cluster size not reached so show the marker.
            if (!marker.getIgnoreCluster() && marker.getIsVisible()) {
                marker.setMapInternal(this.map);
            } else {
                marker.setMapInternal(null);
            }
        } else {
            if (len === this.minClusterSize) {
                // Hide the markers that were showing.
                for (let i = 0; i < len; i++) {
                    this.markers[i].setMapInternal(null);
                }
            }

            if (len >= this.minClusterSize) {
                marker.setMapInternal(null);
            }
        }
    }

    updateAllIconsAndMarkerMap(): void {
        const relevantMarkers = this.getRelevantMarkers();
        const len = relevantMarkers.length;

        for (const marker of relevantMarkers) {
            this.updateIconAndMarkerMap(marker, len);
        }

        this.updateIcon();
    }

    getRelevantMarkers(): IBasicMarker[] {
        return this.markers.filter((x) => !x.getIgnoreCluster());
    }

    getMarkerClusterer(): IMarkerClusterer {
        return this.mapClusterer;
    }

    getBounds(): google.maps.LatLngBounds {
        const bounds = new google.maps.LatLngBounds(this.center, this.center);
        for (const marker of this.getMarkers()) {
            bounds.extend(marker.position);
        }
        return bounds;
    }

    remove() {
        this.clusterIcon.remove();
        this.markers.length = 0;
        delete this.markers;
    }

    getSize(): number {
        return this.markers.length;
    }

    getMarkers(): google.maps.marker.AdvancedMarkerElement[] {
        return this.markers;
    }

    getCenter(): google.maps.LatLng {
        return this.center;
    }

    calculateBounds() {
        const bounds = new google.maps.LatLngBounds(this.center, this.center);
        this.bounds = this.mapClusterer.getExtendedBounds(bounds);
    }

    isMarkerInClusterBounds(marker: google.maps.marker.AdvancedMarkerElement): boolean {
        return this.bounds && this.bounds.contains(marker.position);
    }

    getMap(): google.maps.Map {
        return this.map;
    }

    updateIcon() {
        const shouldCluster = this.mapClusterer.shouldCluster();

        if (!shouldCluster) {
            // The zoom is greater than our max zoom so show all the markers in cluster.
            for (const marker of this.markers) {
                if (marker.getIsVisible()) {
                    marker.setMapInternal(this.map);
                } else {
                    marker.setMapInternal(null);
                }
            }
            return;
        }

        const relevantMarkers = this.getRelevantMarkers();

        if (relevantMarkers.length < this.minClusterSize) {
            // Min cluster size not yet reached.
            this.clusterIcon.hide();
            return;
        }

        const numStyles = this.mapClusterer.getStyles().length;
        const sums = this.mapClusterer.calculator(relevantMarkers, numStyles);
        this.clusterIcon.setCenter(this.center);
        this.clusterIcon.setSums(sums);
        this.clusterIcon.show();
    }
}

class ClusterStyle {
    url: string;
    height?: number;
    width?: number;
    anchor?: any[];
    textColor?: string;
    textSize?: number;
    backgroundPosition?: string;
}

function loadTypes() {
    loaded = true;

    // tslint:disable-next-line:no-shadowed-variable
    ClusterIcon = class ClusterIcon extends google.maps.OverlayView {
        styles: ClusterStyle[];
        padding: number;
        cluster: Cluster;
        center: google.maps.LatLng;
        sums: any;
        visible: boolean;

        div: HTMLDivElement;
        width: number;
        height: number;
        text: string;
        index: number;
        url: string;
        textColor: string;
        anchor: any;
        textSize: number;
        backgroundPosition: any;
        imagePathFunction: (markers: google.maps.marker.AdvancedMarkerElement[]) => string;

        constructor(
            cluster: Cluster,
            styles: ClusterStyle[],
            padding: number,
            imagePathFunction: (markers: google.maps.marker.AdvancedMarkerElement[]) => string,
        ) {
            super();
            this.styles = styles;
            this.padding = padding || 0;
            this.cluster = cluster;
            this.center = null;
            this.div = null;
            this.sums = null;
            this.visible = false;
            this.imagePathFunction = imagePathFunction;

            this.setMap(this.cluster.getMap());
        }

        onAdd() {
            this.div = document.createElement("div");
            if (this.visible) {
                const pos = this.getPosFromLatLng(this.center);
                if (pos) {
                    this.div.style.cssText = this.createCss(pos);
                    this.setDivText();
                }
            }

            const panes = this.getPanes();
            panes.overlayMouseTarget.appendChild(this.div);

            this.div.addEventListener("click", () => {
                if (this.div.style.opacity === "0") return;

                if (!this.cluster.mapClusterer.isPanning()) {
                    this.triggerClusterClick();
                }
            });
        }

        onRemove() {
            if (this.div) {
                if (this.div.parentNode) {
                    this.div.parentNode.removeChild(this.div);
                }

                //@ts-ignore
                this.div.removeAllListeners();

                this.div = null;
            }
        }

        draw() {
            if (!this.visible) return;

            const pos = this.getPosFromLatLng(this.center);
            if (!pos) return;

            this.div.style.top = pos.y + "px";
            this.div.style.left = pos.x + "px";
        }

        hide() {
            if (this.div) {
                this.div.style.display = "none";
            }

            this.visible = false;
        }

        show() {
            if (this.div) {
                const pos = this.getPosFromLatLng(this.center);

                if (pos) {
                    this.div.style.cssText = this.createCss(pos);
                    this.div.style.display = "";
                    this.setDivText();
                }
            }

            this.visible = true;
        }

        isVisible(): boolean {
            return this.visible;
        }

        remove() {
            this.setMap(null);
        }

        setSums(sums: { text: string; index: number }) {
            this.sums = sums;
            this.text = sums.text;
            this.index = sums.index;

            this.setDivText();
            this.useStyle();
        }

        setCenter(center: google.maps.LatLng) {
            this.center = center;
        }

        private setDivText() {
            if (!this.div) return;

            this.div.innerHTML = this.sums.text;

            const showOpaque = this.sums.text === "0";

            this.div.style.opacity = showOpaque ? "0" : "1";
            this.div.style.cursor = showOpaque ? "auto" : "pointer";
        }

        private useStyle() {
            let index = Math.max(0, this.sums.index - 1);
            index = Math.min(this.styles.length - 1, index);
            const style = this.styles[index];

            const resultFromImagePathFunction = this.imagePathFunction
                ? this.imagePathFunction(this.cluster.getMarkers())
                : null;
            this.url = resultFromImagePathFunction
                ? `${resultFromImagePathFunction}${index + 1}.${this.cluster.mapClusterer.getImageExtension()}`
                : style.url;

            this.height = style.height;
            this.width = style.width;
            this.textColor = style.textColor;
            this.anchor = style.anchor;
            this.textSize = style.textSize;
            this.backgroundPosition = style.backgroundPosition;
        }

        private createCss(pos: google.maps.Point): string {
            const style = new Array<string>();
            style.push(`background-image:url(${this.url});`);
            const backgroundPosition = this.backgroundPosition ? this.backgroundPosition : "0 0";
            style.push(`background-position:${backgroundPosition};`);

            if (typeof this.anchor === "object") {
                if (typeof this.anchor[0] === "number" && this.anchor[0] > 0 && this.anchor[0] < this.height) {
                    style.push(`height:${this.height - this.anchor[0]}px; padding-top:${this.anchor[0]}px;`);
                } else {
                    style.push(`height:${this.height}px; line-height:${this.height}px;`);
                }
                if (typeof this.anchor[1] === "number" && this.anchor[1] > 0 && this.anchor[1] < this.width) {
                    style.push(`width:${this.width - this.anchor[1]}px; padding-left:${this.anchor[1]}px;`);
                } else {
                    style.push(`width:${this.width}px; text-align:center;`);
                }
            } else {
                style.push(
                    `height:${this.height}px; line-height:${this.height}px; width:${this.width}px; text-align:center;`,
                );
            }

            const txtColor = this.textColor ? this.textColor : "white";
            const txtSize = this.textSize ? this.textSize : 11;

            style.push(
                `top:${pos.y}px; left:${pos.x}px; color:${txtColor}; position:absolute; font-size:${
                    txtSize
                }px; font-family:Arial,sans-serif; font-weight:bold`,
            );
            return style.join("");
        }

        private triggerClusterClick() {
            const mapClusterer = this.cluster.getMarkerClusterer();

            // Trigger the clusterclick event.
            google.maps.event.trigger(mapClusterer, "clusterclick", this.cluster);

            if (mapClusterer.isZoomOnClick()) {
                this.cluster.getMap().fitBounds(this.cluster.getBounds());

                // Zooming, hide all markers / clusters while zooming
                mapClusterer.resetViewport(true);
            }
        }

        private getPosFromLatLng(latlng: google.maps.LatLng): google.maps.Point {
            const projection = this.getProjection();
            if (!projection) return null;

            const pos = projection.fromLatLngToDivPixel(latlng);
            if (!pos) return null;

            pos.x -= parseInt(this.width / 2 + "", 10);
            pos.y -= parseInt(this.height / 2 + "", 10);
            return pos;
        }
    };

    MapClusterer = class MarkerClusterer extends google.maps.OverlayView {
        // ReSharper disable InconsistentNaming
        // This is called _map  because 'map' already exists on OverlayView
        private readonly _map: google.maps.Map;
        // ReSharper restore InconsistentNaming

        private clusters: Cluster[];
        private readonly sizes: number[];
        private styles: ClusterStyle[];
        private gridSize: number;
        private maxZoom: number;
        private minClusterSize: number;
        private previousIdleZoom: number;
        private previousIdleCenter: google.maps.LatLng;
        private zoomChangedBeforeIdle: boolean;
        private readonly imagePath: string;
        private readonly imageExtension: string;
        private readonly zoomOnClick: boolean;
        private readonly imagePathFunction: () => string = null;
        private dragStartCenter: google.maps.LatLng;
        private dragStartBoundUpdateAfterDistanceInKm: number;
        private unloadOutOfRangeMarkers: boolean;
        private listeners = new Array<google.maps.MapsEventListener>();
        private clusterDistance: number;
        private panning = false;
        private repaintRequestSubject = new Subject<void>();

        constructor(
            map: google.maps.Map,
            private markers?: IBasicMarker[],
            options?: any,
        ) {
            super();

            this._map = map;
            this.markers = [];
            this.clusters = [];
            this.sizes = options["sizes"] || [53, 56, 66, 78, 90];
            options = options || {};
            this.gridSize = options["gridSize"] || 60; // Clustering size
            this.minClusterSize = options["minimumClusterSize"] || 2;
            this.maxZoom = options["maxZoom"] || null;
            this.styles = options["styles"] || [];
            this.imagePath = options["imagePath"] || "../assets/img/m";
            this.imageExtension = options["imageExtension"] || "png";
            this.imagePathFunction = options["imagePathFunction"] || null;
            this.unloadOutOfRangeMarkers = options["unloadOutOfRangeMarkers"] || false;
            this.clusterDistance = options["clusterDistance"] || 40000;

            this.zoomOnClick = true;
            if (options["zoomOnClick"]) {
                this.zoomOnClick = options["zoomOnClick"];
            }

            this.setupStyles();
            this.setMap(this._map);
            this.addListeners();

            // Finally, add the markers
            if (markers && markers.length > 0) {
                this.addMarkers(markers);
            }

            this.repaintRequestSubject.pipe(debounceTime(300)).subscribe(() => {
                // console.log("Doing a repaint after debounce");
                this.repaint();
            });
        }

        private addListeners() {
            // Add the map event listeners
            this.listeners.push(
                google.maps.event.addListener(this._map, "dragstart", () => {
                    this.panning = true;

                    if (this.unloadOutOfRangeMarkers) {
                        this.setDragStartBounds(this._map.getBounds());
                    }
                }),
            );

            this.listeners.push(
                google.maps.event.addListener(this._map, "dragend", () => {
                    // Introduce a minor delay before setting panning to false
                    // That way we can stop certain unwanted interactions, such as clicking a cluster on pan
                    setTimeout(() => {
                        this.panning = false;
                    });
                }),
            );

            this.listeners.push(
                google.maps.event.addListener(this._map, "zoom_changed", () => {
                    this.zoomChangedBeforeIdle = true;
                }),
            );

            // Using center_changed instead of "drag"
            // "drag" doesn't work when you take the map, flick the mouse, and stop pressing the mouse button.
            // In that scenario, the map still moves (glides), but no "drag" is called
            this.listeners.push(
                google.maps.event.addListener(this._map, "center_changed", () => {
                    if (!this.dragStartBoundUpdateAfterDistanceInKm) return;

                    if (this.shouldUnloadOutOfRangeMarkers()) {
                        const distance = this.distanceBetweenPointsInKm(this.dragStartCenter, this._map.getCenter());

                        if (distance > this.dragStartBoundUpdateAfterDistanceInKm) {
                            // const oldClusters = this.clusters.length;
                            this.setDragStartBounds(this._map.getBounds());
                            this.requestRepaint(); // Should not be an entire repaint
                            // this.updateIcons();
                            // console.log(`[drag] Refreshing after ${distance.toFixed(2)}km, went from ${oldClusters} to ${this.clusters.length} clusters`);
                        }
                    }
                }),
            );

            this.listeners.push(
                google.maps.event.addListener(this._map, "idle", () => {
                    // this.setDragStartBounds(null);

                    const previousIdleZoom = this.previousIdleZoom;
                    this.previousIdleZoom = this._map.getZoom();

                    const previousIdleCenter = this.previousIdleCenter;
                    this.previousIdleCenter = this._map.getCenter();

                    const shouldCluster = this.shouldCluster();

                    // If we just panned, instead of zoomed
                    // Be sure to only show marker labels in view
                    if (this.zoomChangedBeforeIdle) {
                        // if (!shouldCluster) {
                        for (const marker of this.markers) {
                            marker.updateLabelVisibility();
                        }
                        // }
                    }

                    this.zoomChangedBeforeIdle = false;

                    if (
                        this.shouldUnloadOutOfRangeMarkers() &&
                        (previousIdleZoom !== this.previousIdleZoom || previousIdleCenter !== this.previousIdleCenter)
                    ) {
                        this.requestRepaint();
                        return;
                    }

                    const isClustering = NumberUtils.isValid(previousIdleZoom) && this.shouldCluster(previousIdleZoom);
                    if (shouldCluster !== isClustering) {
                        // this.resetViewport(true);
                        // this.createClusters();
                        this.requestRepaint();
                    }
                }),
            );
        }

        private setDragStartBounds(bounds: google.maps.LatLngBounds) {
            // console.log("[setDragStartBounds] Entry");
            if (!bounds && !this.dragStartCenter) return;

            if (bounds) {
                // If distance between drag start center & new center > (bounds distance * [PERCENTAGE_DISTANCE_REQUIRED_TO_TRAVERSE_FACTOR]) => refresh
                this.dragStartCenter = bounds.getCenter();
                this.dragStartBoundUpdateAfterDistanceInKm =
                    this.distanceBetweenPointsInKm(bounds.getNorthEast(), bounds.getSouthWest()) * 0.15;
                // console.log(`[setDragStartBounds] Distance needed to refresh: ${this.dragStartBoundUpdateAfterDistanceInKm.toFixed(2)}km`)
            } else {
                this.dragStartCenter = null;
                this.dragStartBoundUpdateAfterDistanceInKm = null;
                // console.log(`[setDragStartBounds] Cleared drag bounds`);
            }
        }

        isPanning(): boolean {
            return this.panning;
        }

        shouldCluster(zoomLevel: number = null): boolean {
            if (zoomLevel === null) {
                zoomLevel = this._map.getZoom();
            }

            return zoomLevel <= this.maxZoom;
        }

        onAdd() {
            // console.log("clusterer onAdd");
            // this.createClusters();
        }

        draw() {}

        getMap(): google.maps.Map {
            return this._map;
        }

        setupStyles() {
            if (this.styles.length) return;

            for (let i = 0, size: number; (size = this.sizes[i]); i++) {
                this.styles.push({
                    url: this.imagePath + (i + 1) + "." + this.imageExtension,
                    height: size,
                    width: size,
                });
            }
        }

        getImagePathFunction(): (markers: google.maps.marker.AdvancedMarkerElement[]) => string {
            return this.imagePathFunction;
        }

        fitMapToMarkers() {
            const bounds = new google.maps.LatLngBounds();

            for (const marker of this.getMarkers()) {
                bounds.extend(marker.getPosition());
            }

            this._map.fitBounds(bounds);
        }

        setStyles(styles: any) {
            this.styles = styles;
        }

        getStyles(): any {
            return this.styles;
        }

        getImageExtension(): string {
            return this.imageExtension;
        }

        isZoomOnClick(): boolean {
            return this.zoomOnClick;
        }

        getMarkers(): IBasicMarker[] {
            return this.markers;
        }

        getTotalMarkers(): number {
            return this.markers.length;
        }

        setMaxZoom(maxZoom: number) {
            this.maxZoom = maxZoom;
        }

        getMaxZoom(): number {
            return this.maxZoom;
        }

        calculator(markers: IBasicMarker[], numStyles: number): { text: string; index: number } {
            markers = markers.filter((x) => x.isActive()); // Filter out "inactive" markers or ignored

            let index = 0;
            let count = markers.length;
            while (count !== 0) {
                count = parseInt(count / 10 + "", 10);
                index++;
            }

            index = Math.min(index, numStyles);
            return {
                text: markers.length.toString(),
                index: index,
            };
        }

        setUnloadOutOfRangeMarkers(unloadOutOfRangeMarkers: boolean) {
            if (this.unloadOutOfRangeMarkers === unloadOutOfRangeMarkers) return;

            this.unloadOutOfRangeMarkers = unloadOutOfRangeMarkers;
            this.requestRepaint();
        }

        shouldUnloadOutOfRangeMarkers(): boolean {
            return this.unloadOutOfRangeMarkers;
        }

        getUnloadOutOfRangeMarkers(): boolean {
            return this.unloadOutOfRangeMarkers;
        }

        addMarkers(markers: IBasicMarker[]) {
            for (const marker of markers) {
                this.addMarker(marker);
            }

            this.createClusters();
        }

        private clearMarkerCluster(marker: IBasicMarker) {
            (marker as any).isAdded = false;
            (marker as any).cluster = null;
        }

        addMarker(marker: IBasicMarker) {
            this.clearMarkerCluster(marker);

            if (marker.draggable) {
                // If the marker is draggable add a listener so we update the clusters on
                // the drag end.
                this.listeners.push(
                    google.maps.event.addListener(marker, "dragend", () => {
                        this.clearMarkerCluster(marker);
                        this.createClusters();
                    }),
                );
            }

            this.markers.push(marker);
        }

        removeMarker(marker: IBasicMarker, redraw = true) {
            marker.setMapInternal(null);

            this.markers = this.markers.filter((x) => x !== marker);

            if (redraw) {
                this.resetViewport();
                this.createClusters();
            }
        }

        getTotalClusters() {
            return this.clusters.length;
        }

        getGridSize(): number {
            return this.gridSize;
        }

        setGridSize(size: number) {
            this.gridSize = size;
        }

        getMinClusterSize(): number {
            return this.minClusterSize;
        }

        setMinClusterSize(size: number) {
            this.minClusterSize = size;
        }

        getExtendedBounds(bounds: google.maps.LatLngBounds, gridSizeFactor = 1): google.maps.LatLngBounds {
            const projection = this.getProjection();
            if (!projection) return null;

            const gridSize = this.gridSize * gridSizeFactor;

            // Turn the bounds into latlng.
            const tr = new google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getNorthEast().lng());
            const bl = new google.maps.LatLng(bounds.getSouthWest().lat(), bounds.getSouthWest().lng());

            // Convert the points to pixels and the extend out by the grid size.
            const trPix = projection.fromLatLngToDivPixel(tr);
            trPix.x += gridSize;
            trPix.y -= gridSize;

            const blPix = projection.fromLatLngToDivPixel(bl);
            blPix.x -= gridSize;
            blPix.y += gridSize;

            // Convert the pixel points back to LatLng
            const ne = projection.fromDivPixelToLatLng(trPix);
            const sw = projection.fromDivPixelToLatLng(blPix);

            // Extend the bounds to contain the new bounds.
            bounds.extend(ne);
            bounds.extend(sw);

            return bounds;
        }

        isMarkerInBounds(marker: google.maps.marker.AdvancedMarkerElement, bounds: google.maps.LatLngBounds) {
            const markerPosition = marker.position;
            return markerPosition && bounds.contains(markerPosition);
        }

        clear() {
            this.resetViewport(true);
            this.markers = [];
        }

        clearClusters() {
            for (const cluster of this.clusters) {
                cluster.remove();
            }

            this.clusters = [];
        }

        resetViewport(hide = false) {
            this.clearClusters();

            if (this.markers) {
                // Reset the markers to not be added and to be invisible.
                for (const marker of this.markers) {
                    if (!marker) continue;

                    this.clearMarkerCluster(marker);

                    if (hide) {
                        marker.setMapInternal(null);
                    }
                }
            }
        }

        requestRepaint() {
            // console.log("[requestRepaint] Request Repaint");
            this.repaintRequestSubject.next();
        }

        repaint() {
            // console.log("[repaint] Entry");
            // Draw on top, THEN remove old clusters
            const oldClusters = this.clusters.slice();
            this.clusters.length = 0;
            this.resetViewport(false);
            this.createClusters(false);

            // Remove the old clusters.
            // Macro-task makes sure there's no "blink" in the artifacts
            setTimeout(() => {
                this.updateClusters();

                if (oldClusters.length) {
                    for (const cluster of oldClusters) {
                        cluster.remove();
                    }
                }
            });

            if (!environment.production) {
                // sanity checks
                // console.table({
                //     oldClusters: oldClusters.length,
                //     newClusters: this.clusters.length,
                //     markers: this.markers.length
                // });
            }
        }

        updateIcon(marker: IBasicMarker) {
            if (!this.clusters) return;

            const clusterWithMarker = this.clusters.find((x) => x.markers.contains(marker));
            if (!clusterWithMarker) return;

            clusterWithMarker.updateIcon();
        }

        updateIcons() {
            if (!this.clusters) return;

            for (const cluster of this.clusters) {
                cluster.updateIcon();
            }
        }

        distanceBetweenPointsInKm(p1: google.maps.LatLng, p2: google.maps.LatLng): number {
            if (!p1 || !p2) return 0;

            // const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
            // const distanceInKm = distanceInMeters / 1000;
            // 2020/02/12 - Robin - Benchmarked both methods, the formula below is faster

            const earthRadiusKm = 6371;
            const dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
            const dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos((p1.lat() * Math.PI) / 180) *
                    Math.cos((p2.lat() * Math.PI) / 180) *
                    Math.sin(dLon / 2) *
                    Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distanceInKm = earthRadiusKm * c;

            return distanceInKm;
        }

        addToClosestCluster(marker: IBasicMarker, immediatelyUpdateUI = true) {
            let clusterDistance = this.clusterDistance;
            let clusterToAddTo: Cluster = null;

            for (const cluster of this.clusters) {
                const center = cluster.getCenter();
                if (center) {
                    const distance = this.distanceBetweenPointsInKm(center, marker.getPosition());
                    if (distance < clusterDistance) {
                        clusterDistance = distance;
                        clusterToAddTo = cluster;
                    }
                }
            }

            if (clusterToAddTo && clusterToAddTo.isMarkerInClusterBounds(marker)) {
                clusterToAddTo.addMarker(marker, immediatelyUpdateUI);
            } else {
                const cluster = new Cluster(this);
                cluster.addMarker(marker, immediatelyUpdateUI);
                this.clusters.push(cluster);
            }
        }

        createClusters(immediatelyUpdateUI = true) {
            if (!this._map || !this.markers || !this.markers.length) return;

            const bounds = this._map.getBounds();
            if (!bounds) return;

            // Get our current map view bounds.
            // Create a new bounds object so we don't affect the map.
            const mapBounds = new google.maps.LatLngBounds(bounds.getSouthWest(), bounds.getNorthEast());
            const extendedBounds = this.getExtendedBounds(mapBounds, 2);
            if (!extendedBounds) return;

            for (const marker of this.markers) {
                if (!marker.getIsVisible()) {
                    marker.setMapInternal(null);
                    continue;
                }

                // Hide markers not in bounds
                if (this.shouldUnloadOutOfRangeMarkers() && !marker.getDisableUnload()) {
                    if (!this.isMarkerInBounds(marker, extendedBounds)) {
                        marker.setMapInternal(null);
                        continue;
                    }
                }

                const markerClusterDisabled = marker.getDisableCluster();
                if (markerClusterDisabled) {
                    if (marker.getMap() !== this._map) {
                        marker.setMapInternal(this._map);
                    }
                    continue;
                }

                const isAdded = (marker as any).isAdded;
                if (!isAdded) {
                    this.addToClosestCluster(marker, immediatelyUpdateUI);
                } else if (!marker.getMap()) {
                    // If added but not on map and in bounds, re-show on map
                    // However, only if cluster isn't showing

                    const cluster = (marker as any).cluster as ICluster;
                    if (!cluster.clusterIcon.isVisible()) {
                        marker.setMapInternal(this._map);
                    }
                }
            }
        }

        updateClusters() {
            if (!this.clusters) return;

            for (const cluster of this.clusters) {
                cluster.updateAllIconsAndMarkerMap();
            }
        }

        remove() {}

        dispose() {
            this.clear();

            for (const listener of this.listeners) {
                if (!listener) continue;
                listener.remove();
            }

            google.maps.event.clearInstanceListeners(this);

            if (this.markers) {
                for (const marker of this.markers) {
                    marker.disposeSignco();
                }

                delete this.markers;
            }

            try {
                this.setMap(null);
            } catch (ex) {
                console.warn("Google Geometry error", ex);
            }
        }
    };
}
