import { Injectable } from "@angular/core";
import { IListResult, SearchParameters } from "@signco/data-access/models/search";
import { IProjectSummary } from "@signco/data-access/models/web";
import { Observable } from "rxjs";
import { CacheOptions } from "../api";
import { WebApiService } from "./web-api";

@Injectable({
    providedIn: "root",
})
export class ProjectWebApi extends WebApiService<IProjectSummary> {
    getRoute(): string {
        return "Projects";
    }

    getProjects$(
        cacheOptions: CacheOptions = null,
        searchParameters: SearchParameters = null,
    ): Observable<IListResult<IProjectSummary>> {
        const url = this.getUrl() + (searchParameters ? "/Untyped" : "");
        const options = this.createOptions(null, searchParameters, null);

        if (cacheOptions) {
            return this.handleCaching(url, options, cacheOptions);
        }

        return this.http.get<IListResult<IProjectSummary>>(url, options);
    }
}
