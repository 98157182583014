import { Constants } from "@signco/core/constants";
import { IBasicMarker, createBasicMarker } from "./basic-marker";
import { BasicMarkerOptions } from "./basic-marker-options";

/**
 * A Google Maps marker that displays an arrow that can be rotated. Is used to show the heading of a measuring point.
 * Some measuring points do not have a heading, instead we show a default marker
 */
export class ArrowMarker {
    static create(position: google.maps.LatLng, rotation: number, showArrow: boolean): IBasicMarker {
        const options = new BasicMarkerOptions(position);
        options.draggable = false;
        options.enableLabel = false;
        const result = createBasicMarker(options);

        if (showArrow) {
            this.rotate(result, rotation);
        }

        return result;
    }

    static rotate(marker: IBasicMarker, rotation: number) {
        if (!marker) return;

        const icon = {
            path: Constants.markerArrowIcon,
            size: new google.maps.Size(30, 30),
            anchor: new google.maps.Point(15, 15),
            fillOpacity: 1,
            scale: 2,
            fillColor: Constants.markerArrowIconColor,
            strokeColor: Constants.markerArrowIconColor,
            rotation: rotation,
        } as google.maps.Symbol;

        marker.setIcon(icon);
        marker.setLabelVisible(false);
    }
}
