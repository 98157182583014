import { Injectable } from "@angular/core";
import { ILoginNotification } from "@signco/data-access/models/login-notification";
import { Rights } from "@signco/data-access/models/rights";
import { SearchParameters } from "@signco/data-access/models/search";
import { AuthorizationInfo, IOrganization, IUser, IUserSummary, Roles } from "@signco/data-access/models/user";
import { LoginNotificationApi } from "@signco/data-access/resource/login-notification.api";
import { UserApi } from "@signco/data-access/resource/user.api";
import { Angulartics2GoogleTagManager } from "angulartics2";
import { BehaviorSubject, Observable } from "rxjs";
import { DomainDataService } from "./domain-data.service";
import { ImpersonationService } from "./impersonation.service";
import { ModalService } from "./modal.service";
import { LocalStorageService } from "./storage.service";

@Injectable({ providedIn: "root" })
export class GlobalEventsService {
    constructor(
        private readonly impersonationService: ImpersonationService,
        private readonly localStorageService: LocalStorageService,
        private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private readonly domainDataService: DomainDataService,
        private readonly userApi: UserApi,
        private readonly modalService: ModalService,
        private readonly loginNotificationApi: LoginNotificationApi,
    ) {
        this.resetRights();

        this.impersonationService.subscribeToRoleImpersonation("authentication.service", () => {
            const authorizationInfo = this.authorizationInfo.getValue();
            this.applyRolesAndRights(authorizationInfo?.user);
        });

        this.impersonationService.subscribeToOrganizationImpersonation("authentication.service", () => {
            const authorizationInfo = this.authorizationInfo.getValue();
            this.applyRolesAndRights(authorizationInfo?.user);
        });
    }

    public isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    public isAuthenticated$: Observable<boolean | null> = this.isAuthenticated.asObservable();

    public authorizationInfo: BehaviorSubject<AuthorizationInfo> = new BehaviorSubject<AuthorizationInfo>(null);
    public authorizationInfo$: Observable<AuthorizationInfo> = this.authorizationInfo.asObservable();

    public currentRights: BehaviorSubject<Rights> = new BehaviorSubject<Rights>(null);
    public currentRights$: Observable<Rights> = this.currentRights.asObservable();

    public currentRoles: BehaviorSubject<Roles[]> = new BehaviorSubject<Roles[]>(null);
    public currentRoles$: Observable<Roles[]> = this.currentRoles.asObservable();

    public currentUserName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public currentUserName$: Observable<string> = this.currentUserName.asObservable();

    public isAuthorized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public isAuthorized$: Observable<boolean> = this.isAuthorized.asObservable();

    public organization: BehaviorSubject<IOrganization> = new BehaviorSubject<IOrganization>(null);
    public organization$: Observable<IOrganization> = this.organization.asObservable();

    setIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated.next(isAuthenticated);
    }

    getIsAuthenticated(): boolean {
        return this.isAuthenticated.getValue();
    }
    getIsAuthorized(): boolean {
        return this.isAuthorized.getValue();
    }

    setIsAuthorized(value: boolean) {
        this.isAuthorized.next(value);
    }

    getCurrentRights(): Rights {
        return this.currentRights.getValue();
    }

    getCurrentRoles(): Roles[] {
        return this.currentRoles.getValue();
    }

    getAuthorizationInfo(): AuthorizationInfo {
        return this.authorizationInfo.getValue();
    }

    setAuthorizationInfo(user: IUser) {
        const prevAuthorizationInfo = this.authorizationInfo.getValue();
        this.angulartics2GoogleTagManager.setUsername(user ? user.email : null);

        if (user == null) {
            this.resetRights();
        } else if (!prevAuthorizationInfo && user) {
            this.showLoginNotifications(true);

            // Apply rights prematurely to be able to instantly show proper UI / load all proper screens in auth.guard
            // If reload fails, it will redirect user anyway
            this.applyRolesAndRights(user);
        }

        const authorizationInfo = new AuthorizationInfo();
        if (user != null) {
            authorizationInfo.isActualDomainAdministrator = this.isActualDomainAdmin(user);
            authorizationInfo.isDomainAdministrator = this.isDomainAdmin(user);
            authorizationInfo.user = user;
            this.authorizationInfo.next(authorizationInfo);
            this.isAuthorized.next(true);
        } else {
            this.authorizationInfo.next(null);
            this.isAuthorized.next(false);
        }
    }

    showLoginNotifications(onlyNew: boolean) {
        const searchParameters = new SearchParameters();

        this.loginNotificationApi.search$(searchParameters, null, false).subscribe((loginNotifications) => {
            const relevantLoginNotifications = loginNotifications.data;

            this.updateLastLoginDate();

            const getNextLoginNotification = (): ILoginNotification => {
                if (!relevantLoginNotifications.length) return null;

                return relevantLoginNotifications.splice(0, 1)[0];
            };

            const onOk = (messageId: string, doNotShowAgain?: boolean) => {
                if (doNotShowAgain && doNotShowAgain === true) {
                    this.localStorageService.setItem(messageId, JSON.stringify(doNotShowAgain));
                }

                showLoginNotification();
            };

            const showLoginNotification = async () => {
                const loginNotification = getNextLoginNotification();
                if (!loginNotification) return;

                const doNotShowAgain = JSON.parse(
                    this.localStorageService.getItem(loginNotification.messageId),
                ) as boolean;
                if (doNotShowAgain === true) {
                    onOk(loginNotification.messageId);
                } else {
                    const translation = this.domainDataService.translate(loginNotification.messageId);
                    this.modalService.info(translation, "general.info", (doNotShowAgain) => {
                        onOk(loginNotification.messageId, doNotShowAgain);
                    });
                }
            };

            showLoginNotification();
        });
    }
    private async updateLastLoginDate(): Promise<void> {
        return new Promise<void>((resolve) => {
            const onSuccess = () => {
                resolve();
            };
            this.userApi.login$().subscribe(onSuccess);
        });
    }

    //#region Permissions

    private getActualRoles(user: IUser): Roles[] {
        return this.getRoles(user, true);
    }

    private isDomainAdmin(user: IUser): boolean {
        return this.getRoles(user).contains(Roles.DomainAdministrator);
    }

    private isActualPlanningManager(user: IUser): boolean {
        return this.getActualRoles(user).contains(Roles.PlanningManager);
    }

    private isActualDomainAdmin(user: IUser): boolean {
        return this.getActualRoles(user).contains(Roles.DomainAdministrator);
    }

    private getRoles(user: IUser, ignoreImpersonation = false): Roles[] {
        if (!user) return [];

        const roles: Roles[] = [];

        // Enum value can be 0, which is falsy but not invalid
        if (
            !ignoreImpersonation &&
            this.impersonationService.role !== null &&
            this.impersonationService.role !== undefined
        ) {
            roles.push(this.impersonationService.role);
        } else {
            roles.push(...user.userRoles); // This pushes every value
        }

        // Add implicit roles to the user based on the roles they have
        // This is the equivalent of `RolesHelper.GetAllRoles` in the backend
        if (roles.contains(Roles.DomainAdministrator)) {
            roles.push(...Object.values(Roles)); // Add all values
        }
        if (roles.contains(Roles.InstallationTechnicalManager)) {
            roles.push(Roles.InstallationManager);
        }
        if (roles.contains(Roles.InstallationManager)) {
            roles.push(Roles.InstallationViewer);
            roles.push(Roles.InstallationRealtimeViewer);
        }

        // Create a Set to remove duplicates, then convert it back to an array
        const result = Array.from(new Set(roles));
        return result;
    }

    private getRights(user: IUser, ignoreImpersonation = false): Rights {
        if (!user) return new Rights();

        // Enum value can be 0, which is falsy but not invalid
        if (
            !ignoreImpersonation &&
            this.impersonationService.role !== null &&
            this.impersonationService.role !== undefined
        ) {
            return this.impersonationService.rights;
        }

        const backendRights = user.rights;
        const rights = new Rights(backendRights);
        return rights;
    }

    getDefaultOrganization(): IOrganization {
        return this.impersonationService.organization || this.getOrganizations().takeFirstOrDefault();
    }

    getOrganizations(): IOrganization[] {
        const user = this.authorizationInfo.getValue()?.user;
        if (!user) return [];
        if (this.impersonationService.organization) return [this.impersonationService.organization];
        return user.userOrganizations.map((x) => x.organization);
    }

    getLinkedOrganizationIds(user: IUser): number[] {
        if (!user) return [];
        if (this.impersonationService.organization) return [this.impersonationService.organization.id];
        return user.linkedOrganizationIds;
    }

    hasAccessToRoleImpersonation(): boolean {
        const user = this.authorizationInfo.getValue()?.user;
        return this.isActualDomainAdmin(user) || this.isActualPlanningManager(user);
    }

    hasMultipleOrganizations(): boolean {
        const user = this.authorizationInfo.getValue()?.user;
        return (
            !this.impersonationService.isImpersonating() &&
            (this.isDomainAdmin(user) || this.getLinkedOrganizationIds(user).length > 1)
        );
    }

    assertAccessToUser(user: IUserSummary): boolean {
        if (!user) return false;

        const currentUser = this.authorizationInfo.getValue()?.user;
        if (currentUser.id == user.id) return true;

        return this.assertAccess(currentUser.linkedOrganizationIds);
    }

    assertAccess(organizationId: number | number[]): boolean {
        if (!organizationId) return false;
        const user = this.authorizationInfo.getValue()?.user;

        const organizationIds = organizationId.toList<number>();
        if (this.impersonationService.isImpersonating())
            return organizationIds.contains(this.impersonationService.organizationId);
        return !!this.getLinkedOrganizationIds(user).find((x) => organizationIds.contains(x));
    }

    isUser(): boolean {
        const user = this.authorizationInfo.getValue()?.user;
        if (user) {
            return !(this.isActualDomainAdmin(user) || this.isDomainAdmin(user));
        }

        return false;
    }

    //#endregion Permissions
    //#region [Rights]
    private applyRolesAndRights(user: IUser) {
        const rights = this.getRights(user);
        const roles = this.getRoles(user);
        this.currentRights.next(rights);
        this.currentRoles.next(roles);
    }

    private resetRights() {
        this.currentRights.next(new Rights());
    }
}
