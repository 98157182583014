import { Component, ViewChild } from "@angular/core";
import { IVmsDisplayedImage } from "@signco/data-access/models/device";
import { DialogComponentBase } from "../dialog/dialog.component";
import { VmsAnimationComponent } from "../vms-animation/vms-animation.component";

@Component({
    selector: "app-vms-animation-dialog",
    templateUrl: "./vms-animation.dialog.component.html",
})
export class VmsAnimationDialogComponent extends DialogComponentBase {
    @ViewChild(VmsAnimationComponent, { static: false }) vmsAnimation: VmsAnimationComponent;

    create(images: IVmsDisplayedImage[]) {
        this.vmsAnimation.images = images;
        this.vmsAnimation.initialize();
        this.openDialog();
    }
}
