import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ParkingBanApi } from "@signco/data-access/resource/parking-ban.api";
import { PageBodyContentComponent } from "@ss/ui/layout/page/page-body/page-body-content/page-body-content.component";
import { AssignmentsService } from "@ss/ui/pages/assignments/services/assignments.service";
import { SharedModule } from "@ss/ui/shared/shared.module";
import { firstValueFrom } from "rxjs";

const TAB_MAPPING = {
    details: "details",
    photos: "photos",
    signscans: "sign-scans",
    parkingbanexceptions: "exceptions",
    "sign-scans": "sign-scans",
    exceptions: "exceptions",
};

@Component({
    selector: "app-parking-ban-redirect",
    standalone: true,
    imports: [PageBodyContentComponent, SharedModule],
    templateUrl: "./parking-ban-redirect.component.html",
    styleUrl: "./parking-ban-redirect.component.scss",
})
export class ParkingBanRedirectComponent implements OnInit {
    private readonly assignmentsService = inject(AssignmentsService);
    private readonly parkingBanApi = inject(ParkingBanApi);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);

    async ngOnInit() {
        const tab = TAB_MAPPING[this.route.snapshot.params["tab"]] || "details";
        const parkingBanId = this.route.snapshot.params["parkingBanId"];

        if (parkingBanId) {
            const parkingBan = await firstValueFrom(
                this.parkingBanApi.get$(
                    parkingBanId,
                    null,
                    this.assignmentsService.getParkingBanServiceRequestOptions(),
                ),
            );
            this.router.navigate(["/assignments", parkingBan.assignment.id, "parking-ban", parkingBan.id, tab]);
        } else {
            this.router.navigate(["/assignments"]);
        }
    }
}
