import { Injectable } from "@angular/core";
import { JsonUtils } from "@signco/core/utils";
import { LocationCreator } from "@signco/data-access/models/location";
import { ITask, TaskCreator, TaskUpdater } from "@signco/data-access/models/task";
import { PlannedEventApi } from "@signco/data-access/resource/planned-event-api";

@Injectable({
    providedIn: "root",
})
export class TaskService {
    constructor(private readonly plannedEventApi: PlannedEventApi) {}

    getCreatorFromServiceModel(taskToBeDuplicated: ITask, newName: string): TaskCreator {
        const task = JsonUtils.deepClone(taskToBeDuplicated); // to prevent modifications on passed task

        task.currentStatus.id = undefined;
        task.id = undefined;

        const taskCreator = Object.assign(new TaskCreator(), task) as TaskCreator;
        taskCreator.assignmentId = task.assignment.id;

        taskCreator.locationId = undefined;
        taskCreator.location = Object.assign(new LocationCreator(), task.location) as LocationCreator;

        taskCreator.name = newName;
        taskCreator.location.code = newName;

        return taskCreator;
    }

    getUpdaterFromServiceModel(taskToBeDuplicated: ITask): TaskUpdater {
        const task = JsonUtils.deepClone(taskToBeDuplicated); // to prevent modifications on passed task

        task.currentStatus.id = undefined;

        const taskUpdater = Object.assign(new TaskUpdater(), task) as TaskUpdater;
        taskUpdater.assignmentId = task.assignment.id;

        return taskUpdater;
    }
}
