import { Injectable } from "@angular/core";
import { ILocationChangedArguments } from "@signco/data-access/models/location-changed-arguments";
import { RealtimeConnection } from "./realtime-connection";

import { Observable, Subject, Subscription } from "rxjs";
import { SignalRService } from "./signalR.service";

@Injectable({
    providedIn: "root",
})
export class LocationRealtimeService {
    private connection: RealtimeConnection;
    private readonly locationChangedSubject = new Subject<ILocationChangedArguments>();

    constructor(private readonly signalRService: SignalRService) {}

    subscribe(callback?: (res: ILocationChangedArguments) => void): Subscription {
        const onSuccess = (data: ILocationChangedArguments) => {
            callback(data);
        };

        const onError = () => {};
        const subscription = this.onLocationChanged().subscribe(onSuccess, onError);

        this.subscribeToSignalR();
        return subscription;
    }

    unsubscribe() {
        // Note that we don't unsubscribe and resubscribe while the user is navigating between the different panels. We keep the subscription as long as the browser is connected
    }

    private onLocationChanged(): Observable<ILocationChangedArguments> {
        return this.locationChangedSubject.asObservable();
    }

    private subscribeToSignalR(): void {
        if (this.connection) return;

        // ContextId is not supported for locations, we pass 0
        // Later we might pass the project-id to only get updates about the projects we're looking at
        this.connection = this.signalRService.CreateConnection(0, "/v1/realtime/Location");

        this.connection.onRegisterEvents = (c) => {
            c.connection.on("changed", (id: number, args: ILocationChangedArguments) => {
                this.locationChangedSubject.next(args);
            });
        };

        this.connection.onDetachEvents = (c) => {
            c.connection.off("changed");
        };

        this.signalRService.startConnection(this.connection);
    }
}
