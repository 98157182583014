import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@ss/ui/shared/shared.module";

@Component({
    selector: "app-page-body-content",
    standalone: true,
    imports: [RouterOutlet, SharedModule, TranslateModule],
    templateUrl: "./page-body-content.component.html",
    styleUrl: "./page-body-content.component.scss",
})
export class PageBodyContentComponent {}
