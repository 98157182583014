import { Pipe, PipeTransform } from "@angular/core";
import { IAddress } from "@signco/data-access/models/location";

@Pipe({
    name: "address",
})
export class AddressPipe implements PipeTransform {
    transform(address: IAddress): string {
        if (!address) return null;

        const lines = [address.line1 ?? "", `${address.zipCode ?? ""} ${address.city ?? ""}`, address.country ?? ""];

        const result = lines
            .map((line) => line.trim())
            .filter((line) => line !== "")
            .join(", ");
        return result;
    }
}
