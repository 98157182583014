import { Injectable } from "@angular/core";
import {
    FilterDescriptor,
    ISearchResult,
    SearchParameters,
    ServiceRequestOptions,
} from "@signco/data-access/models/search";
import { LazyLoadEvent } from "primeng/api";
import { Observable, firstValueFrom } from "rxjs";
import { ApiServiceBase, CacheOptions } from "../api";

@Injectable()
export class WebApiService<T> extends ApiServiceBase {
    protected override getApiVersion(): string {
        return "web";
    }

    search$(
        searchParameters: SearchParameters = null,
        serviceRequestOptions: ServiceRequestOptions = null,
        useCache: boolean | CacheOptions = true,
        routeParams: { [key: string]: string } = null,
        lazyLoadEvent: LazyLoadEvent = null,
    ): Observable<ISearchResult<T>> {
        const options = this.createOptions(serviceRequestOptions, searchParameters, lazyLoadEvent);
        const url = this.getUrl(routeParams) + "/Untyped";
        return this.http.get<ISearchResult<T>>(url, options);
    }

    async get(id: number = null): Promise<T> {
        // Instead of retrieving the data directly by the id, we do a search on the id.
        // That seems very inefficient, we should probably redesign it to do a get on the id
        // However, to do that we need to add the endpoints on the server.

        const searchParameters = new SearchParameters();
        searchParameters.filter = [];
        searchParameters.filter.push(new FilterDescriptor("id", id));

        const data = await firstValueFrom(this.search$(searchParameters));
        if (!data.data || !data.data.length) return null;

        return data.data.takeFirstOrDefault();
    }
}
