import { IDeviceDepotNavigator } from "@signco/data-access/models/device-depot";
import { IDevice } from "./device";
import { IMeasuringPoint } from "./measuring-point";
import { IOfficialLocationId } from "./official-location";
import { IServiceModelBase } from "./servicemodelbase";

export interface IAddress {
    line1?: string;
    zipCode?: string;
    city?: string;
    country?: string;
    countryCode?: string;
}

export interface IAddressWithTimeZone {
    address: IAddress;
    timeZone: string;
}

export interface ICoordinate {
    latitude: number;
    longitude: number;
}

export interface ILocation extends IServiceModelBase {
    id: number;
    code: string;
    description: string;
    isLinkedToGps: boolean;
    address: IAddress;
    coordinate: ICoordinate;
    officialId: IOfficialLocationId;
    measuringPoints?: IMeasuringPoint[];
    devices?: IDevice[];
    organizationId?: number;
    depot: IDeviceDepotNavigator;
    ownerId: number;
    datePeriodSetId: number;
    timeZone: string;
    mapIconId?: number;
    mapIconStateId?: number;
}

export class LocationCreator {
    code: string;
    description: string;
    coordinate: ICoordinate;
    address: IAddress;
    officialId: IOfficialLocationId;
    ownerId?: number;
    datePeriodSetId?: number;
    timeZone?: string;
    mapIconId?: number;
    isLinkedToGps: boolean;
}

export class LocationUpdater extends LocationCreator {
    constructor(existingLocation: ILocation) {
        super();

        this.id = existingLocation.id;
        this.code = existingLocation.code;
        this.description = existingLocation.description;
        this.coordinate = existingLocation.coordinate;
        this.address = existingLocation.address;
        this.officialId = existingLocation.officialId;
        this.ownerId = existingLocation.ownerId;
        this.datePeriodSetId = existingLocation.datePeriodSetId;
        this.timeZone = existingLocation.timeZone;
        this.isLinkedToGps = existingLocation.isLinkedToGps;
        this.mapIconId = existingLocation.mapIconId;
    }

    id: number;
}
